import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Navbar from "../Mynavbar";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component'
import Apiaddres from '../Apiaddres'
import { useReactToPrint } from 'react-to-print'
import Filter from '../Filters/Filter';
import useFetch from '../CustomComponents/useFetch';
import Paginate from '../CustomComponents/Paginate';
import DeletePopup from '../CustomComponents/DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import ActionPopup from '../CustomComponents/ActionPopup';
import MyNavbar from "../Mynavbar";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Footer from "./Footer";

export default function DriversList() {
    document.title = "Driver List | Smart Trucking Services";

    const navigate = useNavigate();
    const lname = sessionStorage.getItem("legalName")

    const [data, setData] = useState([])
    const [searchdriverid, setSearchDriverid] = useState('')
    const [searchdname, setDname] = useState('')
    const [searchlicence, setLicence] = useState('')
    const [searchCity, setCity] = useState('')
    const [searchcell, setCell] = useState('')
    const [searchcompanyname, setCompany] = useState('')
    const [searchprovince, setProvence] = useState('')
    const [safetymeeting, setSafetyMeeting] = useState('')
    const [filter, setFilter] = useState([])
    const [archive, setArchive] = useState('0')
    const [isShowing, setIsShowing] = useState(true);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const handleRowSelected = React.useCallback(state => {
        if (state.selectedRows.length == 0) {
            setArchive(0)
            return;
        }
        if (state.selectedRows[0]['archive'] == 1) {
            setArchive(1)
        }
        else (setArchive(0))
        setSelectedRows(state.selectedRows);
    }, []);
    const activeDriver = () => {
        const filteredArray = data.filter(row => row.archive == 0);
        setFilter(filteredArray);
        setStatus(filteredArray)
        setArchive(0)
    }
    const inActiveDriver = () => {
        const filteredArray = data.filter(row => row.archive == 1);
        setFilter(filteredArray);
        setStatus(filteredArray)
        setArchive(1)
    }
    const allDriver = () => {
        console.log(data)
        setFilter(data)
        setStatus(data)
        setArchive('all')
    }
    const handleDelete = () => {

        fetch(Apiaddres.address + '/archivedriver.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data: selectedRows })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data === "ok") {
                    console.log("ok")
                    window.location.reload();
                }
                else {
                    alert("Error in Archiving the trailer")
                }
                // Optionally, you can handle the response from the server here
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
        navigate('/DriverList')
    };
    const handleUnarchive = () => {

        fetch(Apiaddres.address + '/unarchivedriver.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data: selectedRows })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data === "ok") {
                    console.log("ok")
                    window.location.reload();
                }
                else {
                    alert("Error in Archiving the trailer")
                }
                // Optionally, you can handle the response from the server here
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
        navigate('/DriverList')
    };
    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
            navigate("/login");
        }

    }, [navigate]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(Apiaddres.address + '/driverlist.php?lname="' + lname + '"');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData !== "failed") {
                    const filteredArray = jsonData.filter(row => row.archive == 0);
                    setFilter(filteredArray);
                    setData(jsonData)
                    setStatus(filteredArray)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [])
    const columns = [
        {
            name: "#",
            selector: (row) => row.sr_no,
            sortable: true,


        },
        {
            name: "Driver Name",
            selector: (row) => row.first_name,
            sortable: true,
            cell: (row) => {
              return row.archive == 1 ? (
                <div> <Link to={`/edit-driver/${row.d_id}`}>
                  {row.first_name} {row.last_name}
                </Link>
                <span className="badge badge-table badge-danger mr-1">Archived</span>
                </div>
              ) : (
                <Link to={`/edit-driver/${row.d_id}`}>
                  {row.first_name} {row.last_name}
                </Link>
              );
            },
            // cell: (row) => {
            //     return (
            //         <Link to={`/edit-driver/${row.d_id}`} > {row.first_name}  {row.last_name}</Link>

            //     )
            // }
            
        },
        {
            name: " D.O.B. ",
            selector: (row) => row.dob,
            sortable: true
        },
        {
            name: " Cell No ",
            selector: (row) => row.cell,
            sortable: true
        },
        // {
        //     name:" City ",
        //     selector:(row)=>row.city,
        //     sortable:true
        // },
        // {
        //     name:" Province ",
        //     selector:(row)=>row.province,
        //     sortable:true
        // },
        {
            name: " DL No.",
            selector: (row) => row.driver_licence_no,
            sortable: true
        },

        {
            name: " DL Expiry Date ",
            selector: (row) => row.driver_licence_expiry_date,
            sortable: true
        },

        {
            name: " Medical Expiry Date ",
            selector: (row) => row.driver_medical_expiry_date,
            sortable: true
        },
        // {
        //     name:" Annual Review Date ",
        //     selector:(row)=>row.driver_annual_review_date,
        //     sortable:true
        // },
        {
            name: " Annual Review Due Date ",
            selector: (row) => row.driver_annual_review_due_date,
            sortable: true
        },
        // {
        //     name:" Safety Meeting Date ",
        //     selector:(row)=>row.driver_safety_meeting_date,
        //     sortable:true
        // },
        {
            name: " Safety Meeting Due Date ",
            selector: (row) => row.driver_meeting_due_date,
            sortable: true
        },
        {
            name: " Company Name ",
            selector: (row) => row.company_name,
            sortable: true
        },
        // {
        //     name:" Driver ID",
        //     selector:(row)=>row.d_id,
        //     sortable:true,
        //     cell:(row)=>{return(
        //         <Link to={`/edit-driver/${row.d_id}`} > {row.driver_id} </Link>
        //     )}

        // },
        // {
        //     name:" Last Name ",
        //     selector:(row)=>row.last_name,
        //     sortable:true
        // },

        // {
        //     name:" Address ",
        //     selector:(row)=>row.address,
        //     sortable:true
        // },








    ]
    const [safetydate, setSafetyDate] = useState('')
    const [medicalDate, setMedicalDate] = useState('')
    const [licenceDate, setLicenceDate] = useState('')
    const [annualDate, setAnnualDate] = useState('')
    const DriverStatus = (e) => {
        var value = e.target.value
        setListType(e.target.value)
        if (value == "all") {
            allDriver();
        }
        else if (value == "active") {
            activeDriver()
        }
        else {
            inActiveDriver()
        }
        
        setMedicalDate("default")
        setLicenceDate("default")
        setSafetyDate("default")
        setAnnualDate('default')
    }
    const safetyDueDate = (e) => {
        var value = e.target.value
        setSafetyDate(value)
        if (value == "expire") {
            safetyExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            safetyAboutExpiry()
        }
        setMedicalDate("default")
        setLicenceDate("default")
        setAnnualDate("default")
    }
    const MedicalDate = (e) => {
        var value = e.target.value
        setMedicalDate(value)
        if (value == "expire") {
            mdicalExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            medicalAboutExpiry()
        }
        setSafetyDate("default")
        setLicenceDate("default")
        setAnnualDate("default")
    }
    const LicenceDate = (e) => {
        var value = e.target.value
        setLicenceDate(value)
        if (value == "expire") {
            licenceExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            licenceAboutExpiry()
        }
        setAnnualDate('default')
        setMedicalDate("default")
        setSafetyDate("default")
    }
    const AnnualDate = (e) => {
        var value = e.target.value
        setAnnualDate(value)
        if (value == "expire") {
            annualReviewExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            annualReviewAboutExpiry()
        }
        setMedicalDate("default")
        setLicenceDate("default")
        setSafetyDate("default")
    }
    const safetyExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.annual_safety_expiry_date));
        setFilter(filteredArray);

    }
    const safetyAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0);
        setFilter(filteredArray);
    }
    const mdicalExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.driver_medical_expiry_date));
        setFilter(filteredArray);

    }
    const medicalAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.driver_medical_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_medical_expiry_date)) > 0);
        setFilter(filteredArray);
    }
    const licenceExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.driver_licence_expiry_date));
        setFilter(filteredArray);

    }
    const licenceAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.driver_licence_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_licence_expiry_date)) > 0);
        setFilter(filteredArray);
    }
    const annualReviewExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.driver_annual_review_due_date));
        setFilter(filteredArray);

    }
    const annualReviewAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.driver_annual_review_due_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_annual_review_due_date)) > 0);
        setFilter(filteredArray);
    }
    const[status,setStatus] =  useState([]);
    const tableHeader = {
        rows: {
            style: {

            },
        },
        headCells: {
            style: {

            },
        },
        cells: {
            style: {
                maxWidth: '180px'
            },
        },

    }
    const differenceInDays = (date1, date2) => {
        let one_day = 1000 * 60 * 60 * 24;
        let Result = Math.round((date2.getTime() - date1.getTime()) / one_day);
        let Final_Result = Result.toFixed(0);

        return (Final_Result);
    };
    const comparisionOfDates = (date1) => {
        const currentDate = new Date();
        const dueDate = new Date(date1);
        // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
        currentDate.setHours(0, 0, 0, 0);
        dueDate.setHours(0, 0, 0, 0);
        return currentDate >= dueDate;
    }

    /**conditional Row  Styling ***************** */

    const conditionalRowStyles = [
        {
            when: row => row.archive == 1,// is archive
            style: {
                backgroundColor: '#f0190a',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#f0190a',
                    color: 'white',
                },
            },
        },
        // {   // Those who are going to be expired
        //     when: row => differenceInDays(new Date(), new Date(row.driver_meeting_due_date)) <= 30,
        //     style: {
        //         backgroundColor: '#ff7bb9',
        //         color: 'white',
        //         '&:hover': {


        //         },
        //     },
        // },
        // { // those who are expired
        //     when: row => comparisionOfDates(row.driver_meeting_due_date) ,
        //     style: {
        //         backgroundColor: '#98cld9',
        //         color: 'white',
        //         '&:hover': {

        //         },
        //     },
        // },
        // { // those who are expired
        //     when: row => comparisionOfDates(row.driver_licence_expiry_date) ,
        //     style: {
        //         backgroundColor: '#8a5082',
        //         color: 'white',
        //         '&:hover': {

        //         },
        //     },
        // },	
        // { // those who are expired
        //     when: row => comparisionOfDates(row.driver_annual_review_due_date) ,
        //     style: {
        //         backgroundColor: '#6f5f90',
        //         color: 'white',
        //         '&:hover': {

        //         },
        //     },
        // },
        // { // those who are expired
        //     when: row => comparisionOfDates(row.driver_medical_expiry_date) ,
        //     style: {
        //         backgroundColor: '#ee6c4d',
        //         color: 'white',
        //         '&:hover': {

        //         },
        //     },
        // },	
    ];
    // for Sorting 
    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }

        const result = filteredArray.filter((item) => {
            return item.driver_id.toLowerCase().match(searchdriverid.toLowerCase())
        })
        setFilter(result)
    }, [searchdriverid])

    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }

        const result = filteredArray.filter((item) => {
            return item.province.toLowerCase().match(searchprovince.toLowerCase())
        })
        setFilter(result)
    }, [searchprovince])

    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }

        const result = filteredArray.filter((item) => {
            return item.driver_licence_no.toLowerCase().match(searchlicence.toLowerCase())
        })
        setFilter(result)
    }, [searchlicence])

    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }

        const result = filteredArray.filter((item) => {
            return item.city.toLowerCase().match(searchCity.toLowerCase())
        })
        setFilter(result)
    }, [searchCity])


    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }

        const result = filteredArray.filter((item) => {
            return item.first_name.toLowerCase().match(searchdname.toLowerCase())
        })
        setFilter(result)
    }, [searchdname])

    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }

        const result = filteredArray.filter((item) => {
            return item.cell.toLowerCase().match(searchcell.toLowerCase())
        })
        setFilter(result)
    }, [searchcell])


    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }

        const result = filteredArray.filter((item) => {
            return item.company_name.toLowerCase().match(searchcompanyname.toLowerCase())
        })
        setFilter(result)
    }, [searchcompanyname])


    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }

        const result = filteredArray.filter((item) => {
            return item.driver_safety_meeting_date.toLowerCase().match(safetymeeting.toLowerCase())
        })
        setFilter(result)
    }, [safetymeeting])

    const [listType, setListType] = useState(null);


    return (
        <div>
            <MyNavbar />
            <div className="container-fluid">

                {/********************Filter div ************************ */}
                <Form >
                    <Card className="shadow mb-3 filter-card">
                        <Card.Header className="py-2 d-flex align-items-center" style={{ height: '50px' }}>
                            <h6
                                className="h6 mb-0 text-gray1-800 font-weight-bold text-primary mr-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setIsShowing(!isShowing)}
                            >{isShowing ? 'Hide Filters' : 'Show Filters'}</h6>
                        </Card.Header>
                        {isShowing ? (
                            <Card.Body className="card-body pb-2">
                                <div className="form-row">

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Driver ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchdriverid}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setSearchDriverid(e.target.value)}

                                        />


                                    </div>

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Driver Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchdname}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setDname(e.target.value)}

                                        />


                                    </div>

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Licence #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchlicence}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setLicence(e.target.value)}
                                        />
                                    </div>

                                    {/* <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">City</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchCity}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setCity(e.target.value)}                                   
                                   />     
                           </div> */}

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Cell #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchcell}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setCell(e.target.value)}
                                        />
                                    </div>


                                    {/* <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Company Name</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchcompanyname}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setCompany(e.target.value)}                                   
                                   />     
                           </div> */}

                                    {/* <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Province</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchprovince}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setProvence(e.target.value)}                                   
                                   />     
                           </div>

                         */}
                         
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">DL Expiry Date </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={licenceDate}
                                            onChange={LicenceDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>
                                   


                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Medical Expiry Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={medicalDate}
                                            onChange={MedicalDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>


                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Annual Review Due Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={annualDate}
                                            defaultValue={annualDate}
                                            onChange={AnnualDate}
                                        >
                                            <option value='defalut'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Safety Meeting Due Date</Form.Label>
                                        <Form.Select
                                            value={safetydate}
                                            onChange={safetyDueDate}
                                            className="form-control"
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>
                                </div>

                                {/* <div style={{display:'flex',flexDirection:'row',marginLeft:'10px'}} >
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#dc3545',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Archive Driver</label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#ff7bb9',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Safety about to  expired </label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#98cld9',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Safety expired </label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#8a5082',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Licence expired</label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#6f5f90',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Review expired</label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#005b96',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Medical expired</label>
                           </div>
                           </div> */}
                            </Card.Body>
                        ) : null}
                    </Card>
                </Form>

                {/****************************End of the Filter div ********************************8 */}
                {/********************** Header  *****************************************/}
                <Card className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Driver List</h5>
                        <div className='d-flex'>
                   <Button onClick={()=>window.location.reload()} variant="primary" className="btn-sm mr-2">Refresh</Button>
                            {archive == 1 ?
                                <Button onClick={handleUnarchive} variant="primary" className="btn-sm mr-2" >Restore</Button> :
                                <Button onClick={handleDelete} variant="primary" className="btn-sm mr-2" >Archive</Button>

                            }
                            <Button onClick={() => navigate('/add-driver')} variant="primary" className="btn-sm mr-2">Add</Button>

                            <Form.Select
                                className="form-control"
                                value={listType ?? 'active'}
                                onChange={DriverStatus}
                                style={{ width: 'auto', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                            >
                                <option value='all'>All Drivers</option>
                                <option value='active'>Active Drivers</option>
                                <option value='archive'>Archived Drivers</option>
                            </Form.Select>
                        </div>
                    </Card.Header>

                    {/**************End of Header************************ */}

                    {/** *************************** My DATA TABLE  ********************************* */}

                    <div >
                        <DataTable
                            customStyles={tableHeader}
                            columns={columns}
                            selectableRowsNoSelectAll
                            data={filter}
                            pagination
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            selectableRowsHighlight
                            highlightOnHover
                        />
                    </div>

                    {/** ***************************END OF My DATA TABLE  ********************************* */}

                </Card>
            </div>
            <Footer/>
        </div>
    );
}
