import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes,HashRouter } from 'react-router-dom'
import TruckDetails from './Components/Pages/TruckDetails';
import Dashboard from './Components/Pages/Dashboard';
import EditTruck from './Components/Pages/EditTruck';
import DriversList from './Components/Pages/DriversList';
import { Provider } from 'react-redux'; // Import Provider
import { store, persistor } from './Components/CustomComponents/redux/store'; // Correctly import store and persistor
import { PersistGate } from 'redux-persist/integration/react';
import ReactDatatable from './Components/Pages/ReactDatatable';
import Printtrucklist from './Components/Pages/Printtrucklist';
import EditDrivers from './Components/Pages/EditDrivers';
import AddDriver from './Components/Pages/AddDriver';
import Trailerlist from './Components/Pages/Trailerlist';
import AddTrailer from './Components/Pages/AddTrailer';
import EditTrailer from './Components/Pages/EditTrailer';
import TruckReport from './Components/Pages/TruckReport';
import DriversReport from './Components/Pages/DriverReport';
import TrailerReport from './Components/Pages/TrailerReport';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter basename='/'>
      <Provider store={persistor}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            <Route  path='/'  element={<App/>} />
            <Route path='/Dashboard' element={<Dashboard/>} />
            <Route path='/login' element={<App/>} />
            <Route path='/truck-details' element={<TruckDetails/>} />
            <Route path='/truck-report' element={<TruckReport/>} />
            <Route path='/edit-truck/:id' element={<EditTruck/>} />
            <Route path='/DriverList' element={<DriversList/>} />
            <Route path='/driver-report' element={<DriversReport/>} />
            <Route path='/edit-driver/:id' element={<EditDrivers />} />
            <Route path='/add-driver' element={<AddDriver/>} />  
            <Route path='/trailerlist' element={<Trailerlist/>} />
            <Route path='/trailer-report' element={<TrailerReport/>} />
            <Route path='/add-trailer' element={<AddTrailer/>} /> 
            <Route path='/edit-trailer/:id' element={<EditTrailer />} />   
          </Routes>
        </PersistGate>
      </Provider>
    </BrowserRouter>
 
);

reportWebVitals();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

