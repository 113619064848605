import React from 'react'
import { BsClipboardData, BsPeople, BsPersonPlus, } from 'react-icons/bs'; // Import Bootstrap icons
import {FaTruckFast,FaTrailer} from 'react-icons/fa6'
import {FaUserAstronaut} from 'react-icons/fa'
import NavbarAdmin from '../Mynavbar'
import { useEffect, useState } from "react";
import { Link, json, useNavigate } from "react-router-dom";
import Apiaddres from '../Apiaddres';
import DataTable from 'react-data-table-component';
import { Card, CardContent, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Footer from './Footer';
import { Button } from 'react-bootstrap';
export default function Dashboard() {

  const [truckData, settruckData] = useState([])
  const [trailerData, setTrailerData] = useState([])
  const [driverData, setDriverData] = useState([])
  const lname = sessionStorage.getItem("legalName")
  const sessionpmdays = sessionStorage.getItem("pmdays")
  const [activeData, setActivedata] = useState([])
  const [inactive, setInactive] = useState([])
  const [trucksumerydiv, setTrucksummery] = useState('none')
  const [trailersumerydiv, setTrailersummery] = useState('none')
  const [driversumerydiv, setDriversummery] = useState('none')
  const [drivertext, setdtext] = useState('View')
  const [trailertext, setTtext] = useState('View')
  const [trucktext, setTruckT] = useState("View")
  const [inactiveRowCount, setInactiveRowCount] = useState(0)
  const [activeRowCount, setActiveRowCount] = useState(0)
  const [totalDriverexp, setTotalexp] = useState(0)
  const [totraldrivers, setTotalDrivers] = useState(0)
  const [totaltrailer, settotaltrailer] = useState(0)
  const [totalTrailerexp, setTotaltrailerexp] = useState(0)
  const [activeTrailer, setActiveTrailer] = useState(0)
  const [inactiveTrailer, setInactiveTrailer] = useState(0)
  const [totalTrucks, setTotalTrucks] = useState(0)
  const [totalTruckexp, setTotaltruckexp] = useState(0)
  const [activeTrucks, setActiveTrucks] = useState(0)
  const [inactiveTrucks, setInactiveTrucks] = useState(0)
  let navigate = useNavigate();
  const truckColumns=[
    {
        name:" Unit # ",
        selector:(row)=>row.unit_no,
        sortable:true,
        cell:(row)=>{return(
            <Link to={`/truck-list`} > {row.unit_no} </Link>
        )}

    },

    {
        name:" Anual Safety Expiry Date ",
        selector:(row)=>row.annual_safety_expiry_date,
        sortable:true
    },

    {
        name:" Next PM Expiry Date ",
        selector:(row)=>row.next_pm_due_date,
        sortable:true
    },

]
  // Driver Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Apiaddres.address + '/driverlist.php?lname="' + lname + '"');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if (jsonData !== "failed") {
          var count = 0;
          var countactive = 0;
          var totalDriver = jsonData.length
          var totalexp = 0
          setDriverData(jsonData)
          jsonData.map((row) => {

            if (row.archive === '1') {
              count = count + 1;

            }
            else if (row.archive === '0') {
              countactive = countactive + 1;
            }
            if (comparisionOfDates(row.driver_meeting_due_date)) {
              totalexp = totalexp + 1
            }

          });
          setInactiveRowCount(count);
          setActiveRowCount(countactive);
          setTotalDrivers(totalDriver);
          setTotalexp(totalexp)
          const filteredArray = jsonData.filter(row => {
          
            return (
              comparisionOfDates(row.annual_safety_expiry_date)||
                ( differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0) ||
                comparisionOfDates(row.driver_annual_review_due_date) ||
                (differenceInDays(new Date(), new Date(row.driver_annual_review_due_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_annual_review_due_date)) > 0) ||
                comparisionOfDates(row.driver_medical_expiry_date) ||
                (differenceInDays(new Date(), new Date(row.driver_medical_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_medical_expiry_date)) > 0) ||
                comparisionOfDates(row.driver_licence_expiry_date) ||
                (differenceInDays(new Date(), new Date(row.driver_licence_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_licence_expiry_date)) > 0)
            );
        });
        setDriverData(filteredArray)

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [])
  const driverColumns=[
    {
        name:" Sr#",
        selector:(row)=>row.sr_no,
        sortable:true,
        

    },
    {
        name:" Driver Name ",
        selector:(row)=>row.first_name,
        sortable:true,
        cell:(row)=>{return(
            <Link to={`/edit-driver/${row.d_id}`} > {row.first_name}  {row.last_name}</Link>
        )}
    },
    // {
    //     name:" D.O.B. ",
    //     selector:(row)=>row.dob,
    //     sortable:true
    // },
    // {
    //     name:" cell ",
    //     selector:(row)=>row.cell,
    //     sortable:true
    // },
    // {
    //     name:" City ",
    //     selector:(row)=>row.city,
    //     sortable:true
    // },
    // {
    //     name:" Province ",
    //     selector:(row)=>row.province,
    //     sortable:true
    // },
    // {
    //     name:" Dl No.",
    //     selector:(row)=>row.driver_licence_no,
    //     sortable:true
    // },
    
    {
        name:" Dl Expiry Date ",
        selector:(row)=>row.driver_licence_expiry_date,
        sortable:true
    },
 
    {
        name:" Medical Expiry Date ",
        selector:(row)=>row.driver_medical_expiry_date,
        sortable:true
    },
    // {
    //     name:" Company Name ",
    //     selector:(row)=>row.company_name,
    //     sortable:true
    // },
    // {
    //     name:" Driver ID",
    //     selector:(row)=>row.d_id,
    //     sortable:true,
    //     cell:(row)=>{return(
    //         <Link to={`/edit-driver/${row.d_id}`} > {row.driver_id} </Link>
    //     )}

    // },
    // {
    //     name:" Last Name ",
    //     selector:(row)=>row.last_name,
    //     sortable:true
    // },
  
    // {
    //     name:" Address ",
    //     selector:(row)=>row.address,
    //     sortable:true
    // },
   
 


    // {
    //     name:" Annual Review Date ",
    //     selector:(row)=>row.driver_annual_review_date,
    //     sortable:true
    // },
    // {
    //     name:" Annual Review Due Date ",
    //     selector:(row)=>row.driver_annual_review_due_date,
    //     sortable:true
    // },
    // {
    //     name:" Safety Meeting Date ",
    //     selector:(row)=>row.driver_safety_meeting_date,
    //     sortable:true
    // },
    // {
    //     name:" Safety Meeting Due Date ",
    //     selector:(row)=>row.driver_meeting_due_date,
    //     sortable:true
    // },



]
  //Trailer Data 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Apiaddres.address + '/trailerlist.php?lname=' + lname);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        if (jsonData != "failed") {
          var countinactive = 0;
          var countactive = 0;
          var totalexp = 0
          var total = jsonData.length
          setTrailerData(jsonData)
          jsonData.map((row) => {

            if (row.archive === '1') {
              countinactive = countinactive + 1;

            }
            else if (row.archive === '0') {
              countactive = countactive + 1;
            }
            if (comparisionOfDates(row.annual_safety_expiry_date)) {
              totalexp = totalexp + 1
            }

          });
          setActiveTrailer(countactive);
          setInactiveTrailer(countinactive);
          settotaltrailer(total);
          setTotaltrailerexp(totalexp)
          const filteredArray = jsonData.filter(row => {
          
            return (
              comparisionOfDates(row.annual_safety_expiry_date)||
                ( differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0) ||
                comparisionOfDates(row.next_pm_date) ||
                (differenceInDays(new Date(), new Date(row.next_pm_date)) <= 30 && differenceInDays(new Date(), new Date(row.next_pm_date)) > 0)
            );
        });
        setTrailerData(filteredArray)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [])
  const trailerColumns=[
    {
        name:" Sr # ",
        selector:(row)=>row.sr_no,
        sortable:true,
      


    },
    {
        name:" Unit # ",
        selector:(row)=>row.trailer_unit,
        sortable:true,
        cell:(row)=>{return(
            <Link to={`/edit-trailer/${row.tr_id}`} > {row.trailer_unit} </Link>
        )}

    },
    // {
    //     name:" Last Anual Safety Date ",
    //     selector:(row)=>row.last_annual_safety_date,
    //     sortable:true
    // },
    {
        name:" Anual Safety Expiry Date ",
        selector:(row)=>row.annual_safety_expiry_date,
        sortable:true
    },
    // {
    //     name:" Last PM Date ",
    //     selector:(row)=>row.last_pm_date,
    //     sortable:true
    // },
    {
        name:" Next PM Expiry Date ",
        selector:(row)=>row.next_pm_date,
        sortable:true
    },

    

]
  const comparisionOfDates = (date1) => {
    const currentDate = new Date();
    const dueDate = new Date(date1);
    // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
    currentDate.setHours(0, 0, 0, 0);
    dueDate.setHours(0, 0, 0, 0);
    return currentDate >= dueDate;
  }

  // Truck Details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Apiaddres.address + '/truckdetails.php?lname=' + lname);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();

        if (jsonData != "failed") {
          var countinactive = 0;
          var countactive = 0;
          var total = jsonData.length
          var totalexp = 0;
          settruckData(jsonData)
          jsonData.map((row) => {

            if (row.is_active === '1') {
              countactive = countactive + 1

            }
            else if (row.is_active === '0') {
              countinactive = countinactive + 1;
            }
            if (comparisionOfDates(row.annual_safety_expiry_date)) {
              totalexp = totalexp + 1
            }
          });
          setActiveTrucks(countactive);
          setInactiveTrucks(countinactive);
          setTotaltruckexp(totalexp)
          setTotalTrucks(total);
          const filteredArray = jsonData.filter(row => {
          
            return (
                comparisionOfDates(row.annual_safety_expiry_date) ||
                (differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0) ||
                comparisionOfDates(row.next_pm_due_date) ||
                (differenceInDays(new Date(), new Date(row.next_pm_due_date)) <= 30 && differenceInDays(new Date(), new Date(row.next_pm_due_date)) > 0)
            );
        });
        settruckData(filteredArray)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [])


  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (!loggedIn) {
      navigate("/login");
    }

  }, [navigate]);
  const handleDriver = () => {
    if (driversumerydiv === "grid") {
      setDriversummery('none')
      setdtext("View")
    }
    else {
      setDriversummery('grid')
      setdtext("Hide")
    }
  }
  const handleTrailer = () => {
    if (trailersumerydiv === "grid") {
      setTrailersummery('none')
      setTtext('View')
    }
    else {
      setTrailersummery('grid')
      setTtext("Hide")
    }
  }
  const handleTruck = () => {
    if (trucksumerydiv === "grid") {
      setTrucksummery('none')
      setTruckT("View")
    }
    else {
      setTrucksummery('grid')
      setTruckT("Hide")
    }
  }
  /**conditional Row  Styling ***************** */

const driverConditionalRowStyles = [
  {
          when: row => row.archive == 1,// is archive
          style: {
              backgroundColor: '#dc3545!important',
              color: 'white',
              '&:hover': {
                  backgroundColor: '#f0190a',
                  color: 'white',
              },
          },
      },
  {   // Those who are going to be expired
      when: row => differenceInDays(new Date(), new Date(row.driver_meeting_due_date)) <= 30,
      style: {
          backgroundColor: 'blue',
          color: 'white',
          '&:hover': {
              
              
          },
      },
  },
  { // those who are expired
      when: row => comparisionOfDates(row.driver_meeting_due_date) ,
      style: {
          backgroundColor: 'red',
          color: 'white',
          '&:hover': {
             
          },
      },
  },
  { // those who are expired
      when: row => comparisionOfDates(row.driver_licence_expiry_date) ,
      style: {
          backgroundColor: 'green',
          color: 'white',
          '&:hover': {
             
          },
      },
  },	
  { // those who are expired
      when: row => comparisionOfDates(row.driver_annual_review_due_date) ,
      style: {
          backgroundColor: 'pink',
          color: 'white',
          '&:hover': {
             
          },
      },
  },
  { // those who are expired
      when: row => comparisionOfDates(row.driver_medical_expiry_date) ,
      style: {
          backgroundColor: 'black',
          color: 'white',
          '&:hover': {
             
          },
      },
  },	
];
/**Trailer conditional Row  Styling ***************** */
const trailerConditionalRowStyles = [
	{
		when: row => row.archive == 1,
		style: {
			backgroundColor: '#dc3545!important',
			color: 'white',
			'&:hover': {
				backgroundColor: '#f0190a',
			    color: 'white',
			},
		},
	},
    {
        // those who are goining to be expired 
        when: row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30,
        style: {
            backgroundColor: 'blue',
            color: 'white',
            '&:hover': {
               
            },
        },
    },
    { //Those who are expired annual_safety_expiry_date
        when: row => comparisionOfDates(row.annual_safety_expiry_date) ,
        style: {
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
               
            },
        },
    },
    { //Those who are expired next_pm_date
        when: row => comparisionOfDates(row.next_pm_date) ,
        style: {
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
               
            },
        },
    },
	
];
/** Conditional Row Style************************  */
const truckConditionalRowStyles = [
   
  {
      // those who are goining to be expired 
      when: row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30,
      style: {
          backgroundColor: 'blue',
          color: 'white',
          '&:hover': {
             
          },
      },
  },
  { //Those who are expired annual_safety_expiry_date
      when: row => comparisionOfDates(row.annual_safety_expiry_date) ,
      style: {
          backgroundColor: 'red',
          color: 'white',
          '&:hover': {
             
          },
      },
  },
  { //Those who are expired next_pm_date
      when: row => comparisionOfDates(row.next_pm_due_date) ,
      style: {
          backgroundColor: 'green',
          color: 'white',
          '&:hover': {
             
          },
      },
  },
];
const differenceInDays = (date1, date2) => {
  let one_day = 1000 * 60 * 60 * 24;
  let Result = Math.round((date2.getTime() - date1.getTime()) / one_day);
  let Final_Result = Result.toFixed(0);
  console.log(Final_Result)
  return (Final_Result);
};
const tableHeader = {
  rows: {
  style: {

  },
},
headCells: {
  style: {
         
  },
},
cells: {
  style: {
          maxWidth:'180px'
  },
},
 
}

const [nextpmdays,setnextpmdays] = useState(sessionpmdays)
const handleClickPMDays=()=>{
  fetch(Apiaddres.address+'/updatepmdays.php',{
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',

    },
    body: JSON.stringify({ user_id: sessionStorage.getItem("clientid") ,pm_days:nextpmdays}),
})
  .then((res)=>res.json())
  .then((jsres)=>{
    if(jsres=='okay'){
      sessionStorage.setItem("pmdays",nextpmdays)
    }
  })
}
  return (
    <div>
      <NavbarAdmin />

    <div className="row mb-3">
      <div className="container-fluid">
        <div className="jumbotron shadow-lg p-3 mb-5 bg-body rounded">
          <h1 className="display-5">Welcome, {lname}!</h1>
          <p className="lead"> Complete list of your expired and about to expire assets </p>
          <hr className="my-4" />

           <div className="row row-cols-1 row-cols-md-3 g-4 ">
      <div className="col">
        <div className="card bg-primary text-white shadow-md  ">
          <div className="card-body d-flex align-items-center">
            <FaTruckFast size={80} className="me-3" /> {/* Icon on the left */}
            <div className="content pl-3">
              <h5 className="card-title mb-0">Total Trucks</h5> {/* Decrease margin-bottom */}
              <p className="card-text mb-0">Total number of trucks</p> {/* Decrease margin-bottom */}
              <p className="card-text mb-0">{totalTrucks}</p> {/* Decrease margin-bottom */}
            </div>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="card bg-success text-white shadow-md  ">
          <div className="card-body d-flex align-items-center">
            <FaTrailer size={80} className="me-3" /> {/* Icon on the left */}
            <div className="content pl-3">
              <h5 className="card-title mb-0">Total Trailers</h5> {/* Decrease margin-bottom */}
              <p className="card-text mb-0">Total Number of trailers</p> {/* Decrease margin-bottom */}
              <p className="card-text mb-0">{totaltrailer}</p> {/* Decrease margin-bottom */}
            </div>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="card bg-info text-white  shadow-md  ">
          <div className="card-body d-flex align-items-center">
            <FaUserAstronaut size={80} className="me-3" /> {/* Icon on the left */}
            <div className="content pl-3">
              <h5 className="card-title mb-0">Total Drivers</h5> {/* Decrease margin-bottom */}
              <p className="card-text mb-0">Total number of drivers</p> {/* Decrease margin-bottom */}
              <p className="card-text mb-0">{totraldrivers}</p> {/* Decrease margin-bottom */}
            </div>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="card bg-info text-white  shadow-md  ">
          <div className="card-body d-flex align-items-center">
            <FaUserAstronaut size={80} className="me-3" /> {/* Icon on the left */}
            <div className="content pl-3">
              <h5 className="card-title mb-0">PM Days</h5> {/* Decrease margin-bottom */}
              
              <div  >
                            <label>Select Days</label>
                            <span  className='form-control bg-info text-white' style={{border:'none'}} >
                            <input
                            type="radio"
                            value="90"
                            checked={nextpmdays === '90'}
                            onChange={(e)=>setnextpmdays('90')}
                            /> &nbsp;90 Days &nbsp; &nbsp;
                            <input
                            type="radio"
                            value="120"
                            checked={nextpmdays === '120'}
                            onChange={(e)=>setnextpmdays('120')}
                            /> &nbsp;120 Days
                            </span>
                            <Button onClick={handleClickPMDays}  variant="danger" className="btn-sm mr-2" >Save</Button>
                            
                        </div>
                
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr className="my-4" />

    <div className="row mt-4"> {/* Add margin top to the row */}
      <div className="col-md-6">
        <div className="main-card mb-3 card">
          <div className="card-header">Truck List
            <div className="btn-actions-pane-right">
              <div role="group" className="btn-group-sm btn-group">
                <button className="active btn btn-focus">Complete list of expired and about to expire Trucks </button>
                {/* <button className="btn btn-focus">All Month</button> */}
              </div>
            </div>
          </div>
          <div style={{height:'300px'}} className="table-responsive"> {/* Apply table border Trucks */}
            <table className="align-middle mb-0 table table-bordered table-striped table-hover">
              <thead>
                <tr>
                 
                  <th>Unit #</th>
                  <th className="text-center">Annual Safety Expiry</th>
                  <th className="text-center">PM Expiry</th>
                </tr>
              </thead>
              <tbody>
              {truckData.map((e)=>{
                    return(
                      <tr>
                    <td>
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left mr-3">
                            <div className="widget-content-left">
                              <div className="px-3">{e.unit_no}</div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">{e.annual_safety_expiry_date}</td>
                    <td className="text-center">{e.next_pm_due_date}</td>
                    
                  
                  </tr>
                    )
              })}
                
                {/* Other table rows */}
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
      <div className="col-md-6">
        <div className="main-card mb-3 card">
          <div className="card-header">Trailer List
            <div className="btn-actions-pane-right">
              <div role="group" className="btn-group-sm btn-group">
                <button className="active btn btn-focus">Complete list of expired and about to expire Trailers</button>
                {/* <button className="btn btn-focus">All Month</button> */}
              </div>
            </div>
          </div>
          <div style={{height:'300px'}} className="table-responsive"> {/* Apply table border */}
            <table className="align-middle mb-0 table table-bordered table-striped table-hover">
              <thead>
                <tr>
                
                  <th>Unit #</th>
                  <th className="text-center">Annual Safety Expiry</th>
                  <th className="text-center">PM Expiry </th>
               
                </tr>
              </thead>
              <tbody>
              {trailerData.map((e)=>{
                   return(
                    <tr>
                    <td>
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left mr-3">
                            <div className="widget-content-left">
                              <div className="px-3">{e.trailer_unit}</div>
                              {e.archive =='1' ? ( // Check if is_archive is 1
                              <span className="badge badge-table badge-danger mr-1">Archived</span>                                    ) : (
                                        null // Otherwise, don't display anything
                                  )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">{e.annual_safety_expiry_date}</td>
                    <td className="text-center">{e.next_pm_date}</td>
                    
                  
                  </tr>
                   )
              })}
                {/* Other table rows */}
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
      <div className="col-md-12">
        <div className="main-card mb-3 card">
          <div className="card-header">Driver List
            <div className="btn-actions-pane-right">
              <div role="group" className="btn-group-sm btn-group">
                <button className="active btn btn-focus">Complete list of expired and about to expire Drivers</button>
                {/* <button className="btn btn-focus">All Month</button> */}
              </div>
            </div>
          </div>
          <div style={{height:'300px'}} className="table-responsive"> {/* Apply table border */}
            <table className="align-middle mb-0 table table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>Driver ID</th>
                  <th>Driver Name</th>
                  <th className="text-center">License Expiry</th>
                  <th className="text-center">Medical Expiry</th>
                  <th className="text-center">Annual Safety Expiry</th>
                  <th className="text-center">Annual Review Expiry</th>

               
                </tr>
              </thead>
              <tbody  >
              {driverData.map((e)=>{
                   return(
                    <tr>
                      <td>{e.driver_id}</td>
                    <td>
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left mr-3">
                            <div className="widget-content-left">
                              <div className="px-3">{e.first_name} {e.last_name} </div>
                              {e.archive =='1' ? ( // Check if is_archive is 1
                              <span className="badge badge-table badge-danger mr-1">Archived</span>                                    ) : (
                                        null // Otherwise, don't display anything
                                  )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="text-center">{e.driver_licence_expiry_date}</td>
                    <td className="text-center">{e.driver_medical_expiry_date}</td>
                    <td className="text-center">{e.driver_annual_review_due_date}</td>  
                    <td className="text-center">{e.driver_meeting_due_date}</td>
                    <td className="text-center">
                      <div className="badge badge-warning">{e.next_pm_due_date}</div>
                    </td>
                  
                  </tr>
                   )
              })}
                {/* Other table rows */}
              </tbody>
            </table>
          </div>
          
        </div>
      </div>
    </div>
        </div>
      </div>
    </div>
<Footer/>

    </div>
  )
}
