import * as React from "react";
import { useEffect,useState,useRef } from "react";
import Navbar from "../Mynavbar";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DataTable,{createTheme} from 'react-data-table-component'
import Apiaddres from '../Apiaddres'
import {useReactToPrint} from  'react-to-print'
import Filter from '../Filters/Filter';
import useFetch from '../CustomComponents/useFetch';
import Paginate from '../CustomComponents/Paginate';
import DeletePopup from '../CustomComponents/DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import ActionPopup from '../CustomComponents/ActionPopup';
import MyNavbar from "../Mynavbar";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Footer from "./Footer";
export default function TruckReport() {
    document.title = "Truck Report | Smart Trucking Services";

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClosePDF = () => {
      setAnchorEl(null);
      DownloadPDF(filter);
    };
 
 const handleCloseCSV= ()=>{
    setAnchorEl(null);
    downloadCSV(filter);
  }
    const handleClose =()=>{
        setAnchorEl(null);

    }
  const navigate = useNavigate();
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const lname = sessionStorage.getItem("legalName")
  const [data,setData] = useState([])
  const [searchvin,setSearchVin] = useState('')
  const [searchlname,setLname] = useState('')
  const [searchplateno,setPlatno] = useState('')
  const [searchunit,setUnit] = useState('')
  const [filter,setFilter] = useState([])
  const [isShowing, setIsShowing] = useState(true);
  const contetPdf = useRef()
  
  function convertArrayOfObjectsToPDF(array) {
    const doc = new jsPDF({
        orientation: 'portrait', // 'portrait' or 'landscape'
        unit: 'mm',
        format: 'a4'
    });
    const pageWidth = doc.internal.pageSize.width;
// Define the styles for the theme
const theme = {
    table: { fillColor: [173, 216, 230], lineWidth: 0.1 },
    head: { textColor: [255, 255, 255], lineWidth: 0.1, halign: 'center' ,fillColor: [30, 144, 255], fontSize: 8 },
    body: { textColor: [0, 0, 0], lineWidth: 0.1, fontSize: 8, halign: 'center' },
    alternateRow: { fillColor: [255, 255, 255], padding: 5 }
};


// Add headings or titles
    doc.setFontSize(16);
    const title = "Truck Report";
    const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    const titleX = (pageWidth - titleWidth) / 2;
    const marginTop = 10;
    doc.text(title, titleX, 10); // Centered main title

    // Calculate the available width for the table
    const marginLeft = 5; // Margin on the left side
    const marginRight = 5; // Margin on the right side
    const tableWidth = doc.internal.pageSize.width -  marginLeft - marginRight; // Subtracting 10px margin from left and right

    autoTable(doc, {
        head: [['Sr #','Unit #', 'VIN #',"Plate #", 'Safety Date','Safety Expiry','PM Date','PM Expiry','Company Name']],
        body: array.map(obj => [
            obj.sr_no,
            obj.unit_no,
            obj.vin_no,
            obj.plat_no,
            obj.last_annual_safety_date,
            obj.annual_safety_expiry_date,
            obj.last_pm_date,
            obj.next_pm_due_date,
            obj.legal_name
        ]),
        theme: 'grid',
        startY: marginTop + 5, // Start the table below the title with margin top and additional 20 units
        margin: { left: marginLeft, right: marginRight }, // Set left and right margin
        tableWidth,
        headStyles: theme.head,
        bodyStyles: theme.body,
        alternateRowStyles: theme.alternateRow
        
      })
   
    return doc;
}

function DownloadPDF(data) {
    const doc = convertArrayOfObjectsToPDF(data);
    doc.save("Truck_Report.pdf");
}

  const generatePrint = useReactToPrint({
      content:()=>contetPdf.current,

  })
  {/********************Export to csv functiontion************************ */}
  function convertArrayOfObjectsToCSV(array) {
	let result;

	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = Object.keys(data[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;

			result += item[key];
			
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

function downloadCSV(array) {
	const link = document.createElement('a');
	let csv = convertArrayOfObjectsToCSV(array);
	if (csv == null) return;

	const filename = 'export.csv';

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${csv}`;
	}

	link.setAttribute('href', encodeURI(csv));
	link.setAttribute('download', filename);
	link.click();
}
const[btnmailtext,setBtnmailText] = useState("Send Mail")
function sendPDFMail(data) {
    setBtnmailText("Sending Mail")
    const email = sessionStorage.getItem('email');
    const doc = convertArrayOfObjectsToPDF(data)
    const pdfBlob = doc.output('blob');
    try {
        const formData = new FormData();
        formData.append('reportname', reportname);
        formData.append('email',email)
        formData.append('pdfFile', pdfBlob,"TruckReport.pdf");
        fetch(Apiaddres.mailapi+'pdfsender.php', {
            method: 'POST',
            body: formData
        }).then((res) => res.json())
            .then((jsres) => {
                alert(jsres)
                setBtnmailText("Send Mail")
            });

    } catch (error) {
        // Handle network error
    }
}

const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;
const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);



  const columns=[
    {
        name:" Unit # ",
        selector:(row)=>row.unit_no,
        sortable:true,
        cell:(row)=>{return(
            <Link to={`/edit-truck/${row.unit_no}`} > {row.unit_no} </Link>
        )}

    },
    {
        name:" Legal Name ",
        selector:(row)=>row.legal_name,
        sortable:true
    },
    {
        name:" VIN # ",
        selector:(row)=>row.vin_no,
        sortable:true
    },
    {
        name:" Plate # ",
        selector:(row)=>row.plat_no,
        sortable:true
    },
    {
        name:" Last Anual Safety Date ",
        selector:(row)=>row.last_annual_safety_date,
        sortable:true
    },
    {
        name:" Anual Safety Expiry Date ",
        selector:(row)=>row.annual_safety_expiry_date,
        sortable:true,
        cell:(row)=>{
            return row.last_annual_safety_date==null? (
                <div>  
                  
                </div>
              ) : (
                  <div> {row.annual_safety_expiry_date} </div>
              );
        }
    },
    {
        name:" Last PM Date ",
        selector:(row)=>row.last_pm_date,
        sortable:true
    },
    {
        name:" Next PM Expiry Date ",
        selector:(row)=>row.next_pm_due_date,
        sortable:true
    },

]
const pmExpiry = () => {

    const filteredArray = data.filter(row => comparisionOfDates(row.next_pm_due_date));
    const finalfilteredArray = filteredArray.filter(row => row.next_pm_due_date !== null);
    setFilter(finalfilteredArray); 
}
const pmAboutExpiry = () => {

    const filteredArray = data.filter(row => differenceInDays(new Date(), new Date(row.next_pm_due_date)) <= 30 && differenceInDays(new Date(), new Date(row.next_pm_due_date)) > 0);
    setFilter(filteredArray);
}
const [safetyDAte, setSafetyDate] = useState(' ')
const [pmdate, setPmDate] = useState(' ')
const [reportname,setReportName] = useState('All Trucks')
const SafetyDueDate = (e) => {
    var value = e.target.value
    setSafetyDate(value)
    if (value == "expire") {
        setReportName("Truck Safety Expiry")
        safetyExpiry()
    }
    else if(value=="default"){
        window.location.reload()
    }
    else {
        setReportName("Truck Safety About to Expiry")
        safetyAboutExpiry()
    }
    
    setPmDate('default')
}
const PMDueDate = (e) => {
    var value = e.target.value
    setPmDate(value)
    if (value == "expire") {
        setReportName("PM Expiry")
        pmExpiry()
    }
    else if(value=="default"){
        window.location.reload()
    }
    else {
        setReportName("PM About to Expiry")
        pmAboutExpiry()
    }
    
    setSafetyDate('default')
}
const safetyExpiry = () => {
    const filteredArray = data.filter(row => comparisionOfDates(row.annual_safety_expiry_date));
    const finalfilteredArray = filteredArray.filter(row=> row.annual_safety_expiry_date != null)
    setFilter(finalfilteredArray);
    
}
const safetyAboutExpiry = () => {
    const filteredArray = data.filter(row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0);
    setFilter(filteredArray);
   
}
const differenceInDays = (date1, date2) => {
    let one_day = 1000 * 60 * 60 * 24;
    let Result = Math.round((date2.getTime() - date1.getTime()) / one_day);
    let Final_Result = Result.toFixed(0);

    return (Final_Result);
};
const comparisionOfDates = (date1) => {
    const currentDate = new Date();
    const dueDate = new Date(date1);
    // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
    currentDate.setHours(0, 0, 0, 0);
    dueDate.setHours(0, 0, 0, 0);
    return currentDate >= dueDate;
}

const tableHeader = {
    rows: {
		style: {

		},
	},
	headCells: {
		style: {
           
		},
	},
	cells: {
		style: {
            maxWidth:'180px'
		},
	},
}
/** Conditional Row Style************************ */
const conditionalRowStyles = [
    {
      when: row => row.unit_no =="1091-8258",
      style: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];


    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
          navigate("/login");
        }
    
      }, [navigate]);
      useEffect(()=>{
        const fetchData = async () => {
            try {
                const response = await fetch(Apiaddres.address+'/truckdetails.php?lname='+lname);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                console.log(jsonData)
                if(jsonData!="failed"){
                    setData(jsonData);
                    setFilter(jsonData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    },[])

    // for Sorting 
    useEffect(()=>{
        const result = data.filter((item)=>{
            return item.vin_no.toLowerCase().match(searchvin.toLowerCase())
        })
        setFilter(result)
    },[searchvin])
    
    useEffect(()=>{
        const result = data.filter((item)=>{
            return item.legal_name.toLowerCase().match(searchlname.toLowerCase())
        })
        setFilter(result)
    },[searchlname])
   
    useEffect(()=>{
        const result = data.filter((item)=>{
            return item.unit_no.toLowerCase().match(searchunit.toLowerCase())
        })
        setFilter(result)
    },[searchunit])

    useEffect(()=>{
        const result = data.filter((item)=>{
            return item.plat_no.toLowerCase().match(searchplateno.toLowerCase())
        })
        setFilter(result)
    },[searchplateno])
   
   
/** ********************************  To be  delete  ************************************************************* */

const [listType, setListType] = useState(null);






/*************************End of to be delete*************************************88 */


  return (
<div>
    <MyNavbar/>
<div className="container-fluid">
    
    {/********************Filter div ************************ */}
    <Form >
               <Card className="shadow mb-3 filter-card">
                   <Card.Header className="py-2 d-flex align-items-center" style={{height: '50px'}}>
                       <h6 
                           className="h6 mb-0 text-gray1-800 font-weight-bold text-primary mr-3"
                           style={{cursor: 'pointer'}}
                           onClick={() => setIsShowing(!isShowing)}
                           >{isShowing ? 'Hide Filters' : 'Show Filters'}</h6>
                   </Card.Header>
                   {isShowing ? (
                       <Card.Body className="card-body pb-2">
                           <div className="form-row">

                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Unit #</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchunit}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setUnit(e.target.value)}
                                   
                                   />
                            
                                   
                           </div>

                           {/* <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Legal Name</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchlname}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setLname(e.target.value)}
                                   
                                   />
                            
                                   
                           </div> */}
                           
                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">Plate #</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchplateno}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setPlatno(e.target.value)}
                                   
                                   />
                            
                  
                           </div>

                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                           <Form.Label className="mb-1">VIN #</Form.Label>
                               <Form.Control 
                                   type="text"
                                   value={searchvin}
                                   className='w-25form-control'
                                   placeholder='search'
                                   onChange={(e)=>setSearchVin(e.target.value)}
                                   
                                   />
                           </div>

                           <div className="col-xl-3 col-md-6 mb-3"></div>
                           <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">PM Date </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={pmdate}
                                            onChange={PMDueDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Safety Due Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={safetyDAte}
                                            onChange={SafetyDueDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div> 
                           </div>
                       </Card.Body>
                   ) : null}
               </Card>
           </Form>
   
    {/****************************End of the Filter div ********************************8 */}   
   
    {/********************** Header  *****************************************/}
       <Card className="shadow mb-4">
           <Card.Header className="d-flex align-items-center justify-content-between">
               <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Truck Report</h5>
               <div className='d-flex'>
                   <Button onClick={()=>window.location.reload()} variant="primary" className="btn-sm mr-2">Refresh</Button>
                    <Button onClick={() => sendPDFMail(filter)} variant="primary" className="btn-sm mr-2"> {btnmailtext} </Button>
                   <Button onClick={generatePrint} variant="primary" className="btn-sm mr-2">Print</Button>
                   <Button onClick={handleClick} variant="primary" className="btn-sm mr-2">Download</Button>
                   <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleClosePDF}>PDF</MenuItem>
                    <MenuItem onClick={handleCloseCSV}>CSV</MenuItem>
                  </Menu>
                   {/* <Form.Select 
                       className="form-control"
                       value={listType ?? 'active'}
                       onChange={(e) => setListType(e.target.value)}
                       style={{ width: 'auto', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                       >
                       <option value='all'>All Trucks</option>
                       <option value='active'>Active Trucks</option>
                       <option value='archive'>Archived Trucks</option>
                   </Form.Select> */}
               </div>
           </Card.Header>
   
   {/**************End of Header************************ */}
   
    {/** *************************** My DATA TABLE  ********************************* */}    
       
           <div ref={contetPdf} >
               <DataTable
               customStyles={tableHeader}
               columns={columns}
               data={filter}
               pagination
               highlightOnHover
           />
   </div>
   
    {/** ***************************END OF My DATA TABLE  ********************************* */}    
   
       </Card>
   </div>
   <Footer/>
</div>
  )
}
