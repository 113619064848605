import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component'
import Apiaddres from '../Apiaddres'
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import MyNavbar from "../Mynavbar";
import Footer from "./Footer";
export default function Trailerlist() {
    document.title = "Trailer List | Smart Trucking Services";

    const navigate = useNavigate();
    const lname = sessionStorage.getItem("legalName")
    const [data, setData] = useState([])
    const [searchlname, setLname] = useState('')
    const [searchplateno, setPlatno] = useState('')
    const [searchunit, setUnit] = useState('')
    const [filter, setFilter] = useState([])
    const [isShowing, setIsShowing] = useState(true);
    const [archive, setArchive] = useState('0')
    const [selectedRows, setSelectedRows] = React.useState([]);
    const handleRowSelected = React.useCallback(state => {
        if (state.selectedRows.length == 0) {
            setArchive(0)
            return;
        }
        if (state.selectedRows[0]['archive'] == 1) {
            setArchive(1)
        }
        else (setArchive(0))
        setSelectedRows(state.selectedRows);
    }, []);
    const activeTrailers = () => {
        const filteredArray = data.filter(row => row.archive == 0);
        setArchive(0)
        setFilter(filteredArray);
        setStatus(filteredArray)
    }
    const inActiveTrailers = () => {
        const filteredArray = data.filter(row => row.archive == 1);
        setArchive(1)
        setFilter(filteredArray);
        setStatus(filteredArray)
    }
    const allTrailer = () => {
        setFilter(data)
        setStatus(data)
        setArchive('all')
    }
    const handleDelete = () => {
        fetch(Apiaddres.address + '/archivetrailer.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data: selectedRows })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data === "ok") {
                    console.log("ok")
                    window.location.reload();
                }
                else {
                    alert("Error in Archiving the trailer")
                }
                // Optionally, you can handle the response from the server here
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
    };
    const handleUnarchive = () => {
        fetch(Apiaddres.address + '/unarchivetrailer.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ data: selectedRows })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data === "ok") {
                    console.log("ok")
                    window.location.reload();
                }
                else {
                    alert("Error in Archiving the trailer")
                }
                // Optionally, you can handle the response from the server here
            })
            .catch(error => {
                console.error('There was a problem with your fetch operation:', error);
            });
    };
    const[status,setStatus]=useState([]);
    const columns = [
        {
            name: " # ",
            selector: (row) => row.sr_no,
            sortable: true,


        },
        {
            name: " Unit # ",
            selector: (row) => row.trailer_unit,
            sortable: true,
            cell: (row) => {
                return row.archive == 1 ? (
                  <div>  
                    <Link to={`/edit-trailer/${row.tr_id}`} > {row.trailer_unit} </Link><br/>
                  <span className="badge badge-table badge-danger mr-1">Archived</span>
                  </div>
                ) : (
                    <Link to={`/edit-trailer/${row.tr_id}`} > {row.trailer_unit} </Link>
                );
              },
          

        },
        {
            name: " Last Annual Safety Date ",
            selector: (row) => row.last_annual_safety_date,
            sortable: true
        },
        {
            name: " Annual Safety Expiry Date ",
            selector: (row) => row.annual_safety_expiry_date,
            sortable: true
        },
        {
            name: " Last PM Date ",
            selector: (row) => row.last_pm_date,
            sortable: true
        },
        {
            name: " Next PM Expiry Date ",
            selector: (row) => row.next_pm_date,
            sortable: true
        },
        {
            name: " Company Name ",
            selector: (row) => row.company_name,
            sortable: true
        },


    ]
    const tableHeader = {
        rows: {
            style: {

            },
        },
        headCells: {
            style: {

            },
        },
        cells: {
            style: {
                maxWidth: '1800px'
            },
        },
    }
    const [safetyDAte, setSafetyDate] = useState(' ')
    const [pmdate, setPmDate] = useState(' ')
    const TrailerStatus = (e) => {
        var value = e.target.value
        setListType(e.target.value)
        if (value == "all") {
            allTrailer();
        }
        else if (value == "active") {
            activeTrailers()
        }
        else {
            inActiveTrailers()
        }
        setPmDate('default')
        setSafetyDate('default')
    }
    const SafetyDueDate = (e) => {
        var value = e.target.value
        setSafetyDate(value)
        if (value == "expire") {
            safetyExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            safetyAboutExpiry()
        }
        setPmDate('default')
    }
    const PMDueDate = (e) => {
        var value = e.target.value
        setPmDate(value)
        if (value == "expire") {
            pmExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            pmAboutExpiry()
        }
        setSafetyDate('default')
    }
    const pmExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.next_pm_date));
        setFilter(filteredArray);
    }
    const pmAboutExpiry = () => {

        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.next_pm_date)) <= 30 && differenceInDays(new Date(), new Date(row.next_pm_date)) > 0);
        setFilter(filteredArray);
    }
    const safetyExpiry = () => {
        const filteredArray = status.filter(row => comparisionOfDates(row.annual_safety_expiry_date));
        setFilter(filteredArray);
        
    }
    const safetyAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0);
        setFilter(filteredArray);
    }
    const differenceInDays = (date1, date2) => {
        let one_day = 1000 * 60 * 60 * 24;
        let Result = Math.round((date2.getTime() - date1.getTime()) / one_day);
        let Final_Result = Result.toFixed(0);

        return (Final_Result);
    };
    const comparisionOfDates = (date1) => {
        const currentDate = new Date();
        const dueDate = new Date(date1);
        // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
        currentDate.setHours(0, 0, 0, 0);
        dueDate.setHours(0, 0, 0, 0);
        return currentDate >= dueDate;
    }
    /**conditional Row  Styling ***************** */
    const conditionalRowStyles = [
        {
            when: row => row.archive == 1,
            style: {
                backgroundColor: '#f0190a!important',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#f0190a',
                    color: 'white',
                },
            },
        },
        // {
        //     // those who are goining to be expired 
        //     when: row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30,
        //     style: {
        //         backgroundColor: '#ff7b89',
        //         color: 'white',
        //         '&:hover': {

        //         },
        //     },
        // },
        // { //Those who are expired annual_safety_expiry_date
        //     when: row => comparisionOfDates(row.annual_safety_expiry_date) ,
        //     style: {
        //         backgroundColor: '#8a5082',
        //         color: 'white',
        //         '&:hover': {

        //         },
        //     },
        // },
        // { //Those who are expired next_pm_date
        //     when: row => comparisionOfDates(row.next_pm_date) ,
        //     style: {
        //         backgroundColor: '#6f5f90',
        //         color: 'white',
        //         '&:hover': {

        //         },
        //     },
        // },

    ];
    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
            navigate("/login");
        }

    }, [navigate]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(Apiaddres.address + '/trailerlist.php?lname=' + lname);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData != "failed") {
                    const filteredArray = jsonData.filter(row => row.archive == 0);
                    setFilter(filteredArray);
                    setData(jsonData)
                    setStatus(filteredArray)
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [])
    // for Sorting 



    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }
        const result = filteredArray.filter((item) => {
            return item.company_name.toLowerCase().match(searchlname.toLowerCase())
        })
        setFilter(result)
    }, [searchlname])

    useEffect(() => {
        let filteredArray = []
        if (archive != 'all') {
            filteredArray = data.filter(row => row.archive == archive);
        }
        else {
            filteredArray = data
        }
        const result = filteredArray.filter((item) => {
            return item.trailer_unit.toLowerCase().match(searchunit.toLowerCase())
        })
        setFilter(result)
    }, [searchunit])




    const [listType, setListType] = useState(null);



    return (
        <div>
            <MyNavbar />
            <div className="container-fluid">

                {/********************Filter div ************************ */}
                <Form >
                    <Card className="shadow mb-3 filter-card">
                        <Card.Header className="py-2 d-flex align-items-center" style={{ height: '50px' }}>
                            <h6
                                className="h6 mb-0 text-gray1-800 font-weight-bold text-primary mr-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setIsShowing(!isShowing)}
                            >{isShowing ? 'Hide Filters' : 'Show Filters'}</h6>
                        </Card.Header>
                        {isShowing ? (
                            <Card.Body className="card-body pb-2">
                                <div className="form-row">

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Unit #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchunit}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setUnit(e.target.value)}

                                        />


                                    </div>

                                    <div className="col-xl-3 col-md-6 mb-3"></div>
                                    <div className="col-xl-3 col-md-6 mb-3"></div>
                                    <div className="col-xl-3 col-md-6 mb-3"></div>
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Annual Safety Expiry Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={safetyDAte}
                                            onChange={SafetyDueDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">PM Date </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={pmdate}
                                            onChange={PMDueDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>

                                   

                                </div>
                            </Card.Body>
                        ) : null}
                    </Card>
                </Form>

                {/****************************End of the Filter div ********************************8 */}

                {/********************** Header  *****************************************/}
                <Card className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Trailer List</h5>
                        <div className='d-flex'>
                         <Button onClick={()=>window.location.reload()} variant="primary" className="btn-sm mr-2">Refresh</Button>
                            {archive == 1 ?
                                <Button onClick={handleUnarchive} variant="primary" className="btn-sm mr-2" >Restore</Button> :
                                <Button onClick={handleDelete} variant="primary" className="btn-sm mr-2" >Archive</Button>

                            }
                            <Button onClick={() => navigate('/add-trailer')} variant="primary" className="btn-sm mr-2">Add</Button>

                            <Form.Select
                                className="form-control"
                                value={listType ?? 'active'}
                                onChange={TrailerStatus}
                                style={{ width: 'auto', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                            >
                                <option value='all'>All Trailers</option>
                                <option value='active'>Active Trailers</option>
                                <option value='archive'>Archived Trailers</option>
                            </Form.Select>
                        </div>
                    </Card.Header>

                    {/**************End of Header************************ */}

                    {/** *************************** My DATA TABLE  ********************************* */}

                    <div  >
                        <DataTable
                            customStyles={tableHeader}
                            columns={columns}
                            data={filter}
                            selectableRowsNoSelectAll
                            pagination
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            selectableRowsHighlight
                            highlightOnHover
                        />
                    </div>

                    {/** ***************************END OF My DATA TABLE  ********************************* */}

                </Card>
            </div>
            <Footer/>
        </div>
    )
}
