import { useState, Fragment, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { convertLength } from "@mui/material/styles/cssUtils";
import Apiaddres from "./Components/Apiaddres";
import Footer from "./Components/Pages/Footer";

function App() {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setmessage] = useState('')
  const [openMail, setOpen] = useState(false);
  const [notidialog, setnotidialog] = useState(false)
  const [btntext, setbtntext] = useState("Send")
  const [btnenable, setbtnenable] = useState(false)
  const [clientname, setclinetname] = useState('')
  const [clientpassword, setclinetpassword] = useState('')
  const [clienterror, setclienterror] = useState('none')
  let navigate = useNavigate();

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("loggedIn");
    if (loggedIn) {
      navigate("/Dashboard");
    }

  }, [navigate]);
  const handleMailOpen = () => {
    setOpen(true);

  };
  const handleMailClose = () => {
    setOpen(false);
    setbtnenable(false)
    setbtntext('Send')
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleClientSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true)
    fetch(Apiaddres.address + "/clientlogin.php", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userid: clientname,
        userpass: clientpassword,
      }),
    }).then((res) => res.json())
      .then((response) => {
        
        if (response.status === "success") {
          sessionStorage.setItem("loggedIn", true);
          setIsLoading(false)
          sessionStorage.setItem("clientid", response.data.userid)
          sessionStorage.setItem("legalName", response.data.legal_name)
          sessionStorage.setItem('email',response.data.email)
          sessionStorage.setItem('pmdays',response.data.pmdays)
          navigate('/Dashboard')
        } else { 
          setmessage("Invlaid Credentials.")
          setIsLoading(false)
          setnotidialog(true)
        }
      })
  }
  const footerStyle = {
    backgroundColor: "rgb(1, 49, 90)",
    color: "white",
    textAlign: "left",
    position: "fixed", // Stick to the bottom
    left: 0,
    bottom: 0,
    width: "100%",
  };
  const contentStyle = {
    // marginBottom: "100px", // Adjust this value to match your footer's height
  };
  return (
    <div className="custom-bg">
      <div className="container form-center " >
        <div className="row justify-content-center" >
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-3">
                <div className="pt-3 px-3 pb-1">
                  <div className="text-center">
                    <img id="profile-img" className="profile-img-card" src="./images/logoBlue.png" style={{ width: '300px' }} />
                    <h1 className="h4 text-gray-900 my-3 text-center">Client Login</h1>
                  </div>

                  <form className="user" onSubmit={handleClientSubmit} >
                    <div className="form-group">
                      <Form.Label className="mb-1">Username</Form.Label>
                      <Form.Control
                        type="text"
                        onChange={(e) => setclinetname(e.target.value)}
                        name="username"
                        placeholder="Enter your username"
                        required={true}
                      />
                    </div>

                    <div className="form-group">
                      <Form.Label className="mb-1">Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={(e) => setclinetpassword(e.target.value)}

                        placeholder="Enter your password"
                        required={true}
                      />
                    </div>

                    <button
                      type="submit"
                     
                      className="btn btn-secondary btn-user btn-block"
                    >{ isLoading ? 'Loading...' : 'Login' }</button>
                    <button
                      type="button"
                      onClick={handleMailOpen}
                      className="btn btn-user btn-block"
                    > Forgot Password </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* ****************Forgot Email Dialog******************************************  */}

      <Fragment>

        <Dialog
          open={openMail}
          onClose={handleMailClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              setbtntext("Sending....")
              setbtnenable(true)
              const length = 8; // specify the length of the password
              // const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]|:;"<>,.?/~'; // specify the characters to include in the password
              const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'; // specify the characters to include in the password
              let password = '';
              for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * charset.length);
                password += charset[randomIndex];
              }
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const email = formJson.email;

              fetch(Apiaddres.address + "/checkregisteredid.php", {
                method: 'POST',
                body: JSON.stringify(
                  {
                    userid: email,
                    password: password
                  })
              }).then((res) => res.json())
                .then((jsres) => {
                  console.log(jsres)
                  if (jsres == 'found') {

                    /***************************** Sending mail **************************/
                    try {
                      var message="Dear user,<br><br>This is an automated e-mail response for your recent password change request for your SmartTrucking Consultants Client Portal..<br><br><br> New Password: "+password+"</b><br><br>Thank you,<br><br>Smart Trucking Consultants";
                      var subject="Smart Trucking Service's New Password"
                      fetch(Apiaddres.mailapi+'inde.php', {
                        method: 'POST',
                        body: JSON.stringify(
                          {
                            username: "User",
                            email: email,
                            password: password,
                            message:message,
                            subject:subject
                          })
                      }).then((res) => res.json())
                        .then((jsres) => {
                          if (jsres == 'Message has been sent') {
                            handleMailClose();
                            setmessage("You will receive a password via email shortly.")
                            setnotidialog(true)
                          }
                        });
                    } catch (error) {
                      setmessage('Error sending email:' + error);
                      setnotidialog(true)
                      handleMailClose();
                      // Handle network error
                    }

                    /*************************ENd of mail block************************** */
                  }
                  else {
                    handleMailClose();
                    setmessage("Sorry, No Client Account Found")
                    setnotidialog(true)
                  }
                });

            },
          }}
        >
          <DialogTitle>Request for password reset</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To receive the new password please enter your notification email ,
              if we found that email as registered mail then we wil send you the password
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="name"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={btnenable} onClick={handleMailClose}>Cancel</Button>
            <Button type="submit" disabled={btnenable} >{btntext}</Button>
          </DialogActions>
        </Dialog>
      </Fragment>

      {/* **************End of forgot email dialog ***************************************/}

      {/****************** Notification Dialog*****************************/}

      <Fragment>

        <Dialog
          open={notidialog}
          onClose={() => setnotidialog(false)}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              setnotidialog(false)
            },
          }}
        >
          <DialogTitle>Message</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ minWidth: '400px' }} >
              {message}
            </DialogContentText>

          </DialogContent>
          <DialogActions>

            <Button type="submit" >Okay</Button>

          </DialogActions>
        </Dialog>
      </Fragment>

      {/****************** End of Notification Dialog*****************************/}

    <div>
       <div style={contentStyle}>
        {/* Your main content goes here */}
      </div>
      <footer  style={footerStyle}>
    <div className="text-left p-3">
    Copyright © <a style={{color: "#ff8442"}} target="_blank" href="https://smartrucking.ca/">Smart Trucking Services</a> 2014. All Rights Reserved. Smart Trucking Services one stop shop for all trucking services <br/>
Design by <a style={{color: "#ff8442"}} target="_blank" href="https://www.sheikhskilledsolutions.com/">Sheikh Skilled Solutions Inc</a>.
    </div>

  </footer>
    </div>

    </div>

  );
}

export default App;
