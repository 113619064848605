import React from 'react'
import { useParams,useNavigate, json } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import MyNavbar from '../Mynavbar';
import Apiaddres from '../Apiaddres';
import Footer from './Footer';
export default function AddTrailer() {
    document.title = "Add Trailers | Smart Trucking Services";
    const cmpname = sessionStorage.getItem("legalName")
    let navigate = useNavigate();
    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
          navigate("/login");
        }
        
      }, [navigate]);

      const cardRef = useRef();
        const [unitNo,setUnitNo]= useState('')
        const [lastPm,setLastpm]=useState('')
        const [company,setCompany] = useState(cmpname)
        const [annualReiew,setAnnualReciew] =useState('')
        const [savedialogalter,setsavedialogalert] = useState('none')
        const [message, setMessage] = useState('')
        const userId = sessionStorage.getItem('clientid')
        const handleSubmit=(e)=>{
       e.preventDefault()
          fetch(Apiaddres.address+"/addtrailer.php",{
              method:'POST',
              headers: { 
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  unitNo:unitNo,
                  lastPm:lastPm,
                  annualReiew:annualReiew,
                  company:company,
                  userId:userId,
                  pm_days:sessionStorage.getItem("pmdays")

              })
          }).then((res)=>res.json())
          .then((jsres)=>{
              console.log(jsres)
              if(jsres=="success"){
                  setsavedialogalert('inline-block')
                  setMessage('Trailer Added Successfully')
              }
              else{
                 
            setsavedialogalert('inline-block')
            setMessage('Trailer id already exist.')
              }
          })
        }      
  return (
    <div>
    <MyNavbar/>
     <div className="container-fluid">
             
             <form onSubmit={handleSubmit} >
                 <Card ref={cardRef} className="shadow mb-4">
                     <Card.Header className="d-flex align-items-center justify-content-between">
                         <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Add Trailer</h5>
                         <div>  
                         <Button onClick={()=>window.location.reload()} variant="primary" className="btn-sm " style={{marginRight:'10px'}} >ADD</Button>
                         <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                         </div>
                     </Card.Header>
                     <Card.Body>
                         
         
                     <fieldset className='mb-3'>
                             <legend>Trialer Info</legend>
                             <div className="form-row">

                             <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Unit No</label>
                                     <input
                                     type='text'
                                     value={unitNo}
                                     onChange={(e)=>setUnitNo(e.target.value)}
                                     className='form-control'  
                                     />
                                 </div>

                             <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Last Annual Date *</label>
                                     <input
                                     type='date'
                                     required
                                     value={annualReiew}
                                     onChange={(e)=>setAnnualReciew(e.target.value)}
                                     className='form-control'  
                                     />
                                 </div>

                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Last PM Date *</label>
                                     <input
                                     type='date'
                                     required
                                     value={lastPm}
                                     onChange={(e)=>setLastpm(e.target.value)}
                                     className='form-control'  
                                     />
                                 </div>
                                
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Company Name</label>
                                     <input
                                     value={company}
                                     disabled
                                     onChange={(e)=>setCompany((e.target.value))}
                                     className='form-control'
                                     type='text'
                                     />
                                 </div>

                                
         
                                 
                             </div>
         
                         </fieldset>
         
         
                     </Card.Body>
                     <Card.Footer className='text-right'>
                         <button 
                             type="submit" 
                            
                             className="btn btn-primary btn-submit w-100px"
                             > Submit </button>
                     </Card.Footer>
                 </Card>
             </form>
         
         {/************************* Message Box Save Check *****************************/}
         
             <div style={{display:savedialogalter}} >
             <div>
         <div className="card shadow mb-4" style={{
             position: 'fixed',
             height: 'calc(100vh + 10px)',
             width: 'calc(100vw + 10px)',
             top: '-10px',
             left: '-10px',
             background: '#000000dd',
             display: 'flex',
             zIndex: 100,
             justifyContent: 'center',
             alignItems: 'center',
         }}>
         
             <h4 className="h4 mb-1 mt-2 text-white">
                 Note
             </h4>
             <div > <p className='text-white' > {message}  </p> </div>
                 
                 <div>
                     <button onClick={()=>setsavedialogalert('none')}  className="btn btn-success btn-submit w-100px mx-2">Okay</button>
                 </div>
             
         </div>
         </div>
             </div>
         
         
         </div>
         <Footer/>
    </div>
  )
}
