import React from 'react'
import { useParams,useNavigate, json } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import MyNavbar from '../Mynavbar';
import Apiaddres from '../Apiaddres';
import Footer from './Footer';
import session from 'redux-persist/lib/storage/session';
export default function EditTrailer() {
    document.title = "Edit Driver | Smart Trucking Services";
    const { id } = useParams();
    const cmpname = sessionStorage.getItem("legalName")
    let navigate = useNavigate();
    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
          navigate("/login");
        }
        
      }, [navigate]);

      useEffect(()=>{
        const fetchData =  () => {

                fetch(Apiaddres.address+'/trailerinfo.php?tr_id='+id).then((res)=>res.json())
                .then((jsres)=>{
                    jsres.forEach(data => {
                        
                    setUnitNo(data.trailer_unit)
                    setAnnualReciew(data.last_annual_safety_date)
                    setLastpm(data.last_pm_date)
                    setCompany(data.company_name)
                    });
                })
                
        };
    
        fetchData();
    },[])

    
    const cardRef = useRef();
    const [unitNo,setUnitNo]= useState('')
        const [lastPm,setLastpm]=useState('')
        const [company,setCompany] = useState(cmpname)
        const [annualReiew,setAnnualReciew] =useState('')
    const [savedialogalter,setsavedialogalert] = useState('none')
    const [driverId,setDriverId] =useState('')
    const [message, setMessage] = useState('')
    const handleSubmit=(e)=>{
      e.preventDefault();
      fetch(Apiaddres.address+"/edittrailer.php",{
          method:'POST',
          headers: { 
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              unitNo:unitNo,
              annualReiew:annualReiew,
              lastPm:lastPm,
              tr_id:id,
              company:company,
              pm_days:sessionStorage.getItem("pmdays")
          })
      }).then((res)=>res.json())
      .then((jsres)=>{
          if(jsres=="success"){
              setsavedialogalert('inline-block')
              setMessage('Trailer Udated Successfully')
          }
          else{
             
            setsavedialogalert('inline-block')
            setMessage('Trailer id already exist.')
          }
      })
    }  

  return (
    <div>
    <MyNavbar/>
     <div className="container-fluid">
             
             <form onSubmit={handleSubmit} >
                 <Card ref={cardRef} className="shadow mb-4">
                     <Card.Header className="d-flex align-items-center justify-content-between">
                         <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Edit Trailer</h5>
                         <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                     </Card.Header>
                     <Card.Body>
                         
         
                     <fieldset className='mb-3'>
                             <legend>Trailer info</legend>
                             <div className="form-row">
                                
                             <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Unit No</label>
                                     <input
                                     type='text'
                                     value={unitNo}
                                     onChange={(e)=>setUnitNo(e.target.value)}
                                     className='form-control'  
                                     />
                                 </div>

                             <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Last Annual Safety Date *</label>
                                     <input
                                     type='date'
                                     
                                     value={annualReiew}
                                     onChange={(e)=>setAnnualReciew(e.target.value)}
                                     className='form-control'  
                                     />
                                 </div>

                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Last PM Date *</label>
                                     <input
                                    
                                     type='date'
                                     value={lastPm}
                                     onChange={(e)=>setLastpm(e.target.value)}
                                     className='form-control'  
                                     />
                                 </div>
         
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Company Name</label>
                                     <input
                                     value={company}
                                     disabled
                                     onChange={(e)=>setCompany((e.target.value))}
                                     className='form-control'
                                     type='text'
                                     />
                                 </div>   
                                 
                             </div>
         
                         </fieldset>
         
         
                     </Card.Body>
                     <Card.Footer className='text-right'>
                         <button 
                             type="submit" 
                             className="btn btn-primary btn-submit w-100px"
                             > Submit </button>
                     </Card.Footer>
                 </Card>
             </form>
         
         {/************************* Message Box Save Check *****************************/}
         
             <div style={{display:savedialogalter}} >
             <div>
         <div className="card shadow mb-4" style={{
             position: 'fixed',
             height: 'calc(100vh + 10px)',
             width: 'calc(100vw + 10px)',
             top: '-10px',
             left: '-10px',
             background: '#000000dd',
             display: 'flex',
             zIndex: 100,
             justifyContent: 'center',
             alignItems: 'center',
         }}>
         
             <h4 className="h4 mb-1 mt-2 text-white">
                 Note
             </h4>
             <div > <p className='text-white' > {message}  </p> </div>
                 
                 <div>
                     <button onClick={()=>setsavedialogalert('none')}  className="btn btn-success btn-submit w-100px mx-2">Okay</button>
                 </div>
             
         </div>
         </div>
             </div>
         
         
         </div>
         <Footer/>
    </div>
  )
}
