
import React, { Component } from 'react'

export default class Apiaddres extends Component {
//  static address = "http://localhost/st/backend/Customer_apis"
//  static mailapi = "http://localhost/smail1/"
 static address = "https://smartrucking.ca/backend/customer_apis"
 static mailapi="https://smartrucking.ca/coremail/"
 }

// forgwt mail ,edit customer,add customer,setcostomer pass,clientlogin, 20.104.142.12    V7KGl53c