import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Navbar from "../Mynavbar";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component'
import Apiaddres from '../Apiaddres'
import { useReactToPrint } from 'react-to-print'
import Filter from '../Filters/Filter';
import useFetch from '../CustomComponents/useFetch';
import Paginate from '../CustomComponents/Paginate';
import DeletePopup from '../CustomComponents/DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import ActionPopup from '../CustomComponents/ActionPopup';
import MyNavbar from "../Mynavbar";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import session from "redux-persist/lib/storage/session";
import Footer from "./Footer";

export default function DriversReport() {
    document.title = "Driver Report | Smart Trucking Services";
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosePDF = () => {
        setAnchorEl(null);
        DownloadPDF(filter);
    };

    const handleCloseCSV = () => {
        setAnchorEl(null);
        downloadCSV(filter);
    }
    const handleClose = () => {
        setAnchorEl(null);

    }
    const navigate = useNavigate();
    const lname = sessionStorage.getItem("legalName")
    const [archive, setArchive] = useState('0')
    const [data, setData] = useState([])
    const [searchdriverid, setSearchDriverid] = useState('')
    const [searchdname, setDname] = useState('')
    const [searchlicence, setLicence] = useState('')
    const [searchCity, setCity] = useState('')
    const [searchcell, setCell] = useState('')
    const [searchcompanyname, setCompany] = useState('')
    const [searchprovince, setProvence] = useState('')
    const [safetymeeting, setSafetyMeeting] = useState('')
    const [filter, setFilter] = useState([])
    const [isShowing, setIsShowing] = useState(true);
    const contetPdf = useRef()
    const [pdfFile, setPdfFile] = useState(null);
    const generatePdf = useReactToPrint({
        content: () => contetPdf.current,

    })
    const activeDriver = () => {
        const filteredArray = data.filter(row => row.archive == 0);
        setFilter(filteredArray);
        setStatus(filteredArray)
        setArchive(0)
    }
    const inActiveDriver = () => {
        const filteredArray = data.filter(row => row.archive == 1);
        setFilter(filteredArray);
        setStatus(filteredArray)
        setArchive(1)
    }
    const allDriver = () => {
        setFilter(data)
        setArchive('all')
        setStatus(data)
    }
    {/********************Export to csv functiontion************************ */ }
    function convertArrayOfObjectsToCSV(array) {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }
    /**Download Pdf ***************************** */
    function convertArrayOfObjectsToPDF(array) {
        const doc = new jsPDF({
            orientation: 'portrait', // 'portrait' or 'landscape'
            unit: 'mm',
            format: 'a4'
        });
        const pageWidth = doc.internal.pageSize.width;
        // Define the styles for the theme
        const theme = {
            table: { fillColor: [173, 216, 230], lineWidth: 0.1 },
            head: { textColor: [255, 255, 255], lineWidth: 0.1, halign: 'center', fillColor: [30, 144, 255], fontSize: 8 },
            body: { textColor: [0, 0, 0], lineWidth: 0.1, fontSize: 8, halign: 'center' },
            alternateRow: { fillColor: [255, 255, 255], padding: 5 }
        };


        // Add headings or titles
        doc.setFontSize(16);
        const title = "Driver Report";
        const titleWidth = doc.getStringUnitWidth(title) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const titleX = (pageWidth - titleWidth) / 2;
        const marginTop = 10;
        doc.text(title, titleX, 10); // Centered main title

        // Calculate the available width for the table
        const marginLeft = 5; // Margin on the left side
        const marginRight = 5; // Margin on the right side
        const tableWidth = doc.internal.pageSize.width - marginLeft - marginRight; // Subtracting 10px margin from left and right

        autoTable(doc, {
            head: [['Sr #', 'Driver Name', 'D.O.B', "Mobile", 'City', 'Province', 'Dl #', 'Dl Expiry Date', 'Medical Expiry Date', 'Company Name']],
            body: array.map(obj => [
                obj.sr_no,
                obj.first_name,
                obj.dob,
                obj.cell,
                obj.city,
                obj.province,
                obj.driver_licence_no,
                obj.driver_licence_expiry_date,
                obj.driver_medical_expiry_date,
                obj.company_name
            ]),
            theme: 'grid',
            startY: marginTop + 5, // Start the table below the title with margin top and additional 20 units
            margin: { left: marginLeft, right: marginRight }, // Set left and right margin
            tableWidth,
            headStyles: theme.head,
            bodyStyles: theme.body,
            alternateRowStyles: theme.alternateRow

        })

        return doc;
    }

    function DownloadPDF(data) {
        const doc = convertArrayOfObjectsToPDF(data);
        doc.save("Driver_Report.pdf");
    }
    const[btnmailtext,setBtnmailText] = useState("Send Mail")
    function sendPDFMail(data) {
        setBtnmailText('Sending Mail')
        const email = sessionStorage.getItem('email');
        const doc = convertArrayOfObjectsToPDF(data)
        const pdfBlob = doc.output('blob');
        try {
            const formData = new FormData();
            formData.append('reportname', reportname);
            formData.append('email', email);
            formData.append('pdfFile', pdfBlob,"DriverReport.pdf");
            fetch(Apiaddres.mailapi+'pdfsender.php', {
                method: 'POST',
                body: formData
            }).then((res) => res.json())
                .then((jsres) => {
                    alert(jsres)
                    setBtnmailText("Send Mail")
                });

        } catch (error) {
            // Handle network error
        }
    }
    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
            navigate("/login");
        }

    }, [navigate]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(Apiaddres.address + '/driverlist.php?lname="' + lname + '"');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData !== "failed") {
                    
                const filteredArray =jsonData.filter(row => row.archive == 0);
                setFilter(filteredArray);
                setData(jsonData)
                setStatus(filteredArray)
                    
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [])
    const columns = [
        {
            name:"#",
            selector:(row)=>row.sr_no,
            sortable:true,
            
    
        },
        {
            name: " Driver Name ",
            selector: (row) => row.first_name,
            sortable: true,
            cell: (row) => {
                return row.archive == 1 ? (
                  <div> <Link to={`/edit-driver/${row.d_id}`}>
                    {row.first_name} {row.last_name}
                  </Link>
                  <span className="badge badge-table badge-danger mr-1">Archived</span>
                  </div>
                ) : (
                  <Link to={`/edit-driver/${row.d_id}`}>
                    {row.first_name} {row.last_name}
                  </Link>
                );
              },

        },
        {
            name: " D.O.B. ",
            selector: (row) => row.dob,
            sortable: true
        },
        {
            name: " Mobile ",
            selector: (row) => row.cell,
            sortable: true
        },
        {
            name: " City ",
            selector: (row) => row.city,
            sortable: true
        },
        {
            name: " Province ",
            selector: (row) => row.province,
            sortable: true
        },
        {
            name: " DL No.",
            selector: (row) => row.driver_licence_no,
            sortable: true
        },

        {
            name: " DL Expiry Date ",
            selector: (row) => row.driver_licence_expiry_date,
            sortable: true
        },

        {
            name: " Medical Expiry Date ",
            selector: (row) => row.driver_medical_expiry_date,
            sortable: true
        },
            // {
    //     name:" Annual Review Date ",
    //     selector:(row)=>row.driver_annual_review_date,
    //     sortable:true
    // },
    {
        name:" Annual Review Due Date ",
        selector:(row)=>row.driver_annual_review_due_date,
        sortable:true
    },
    // {
    //     name:" Safety Meeting Date ",
    //     selector:(row)=>row.driver_safety_meeting_date,
    //     sortable:true
    // },
    {
        name:" Safety Meeting Due Date ",
        selector:(row)=>row.driver_meeting_due_date,
        sortable:true
    },
        {
            name: " Company Name ",
            selector: (row) => row.company_name,
            sortable: true
        },
        // {
        //     name:" Driver ID",
        //     selector:(row)=>row.d_id,
        //     sortable:true,
        //     cell:(row)=>{return(
        //         <Link to={`/edit-driver/${row.d_id}`} > {row.driver_id} </Link>
        //     )}

        // },
        // {
        //     name:" Last Name ",
        //     selector:(row)=>row.last_name,
        //     sortable:true
        // },

        // {
        //     name:" Address ",
        //     selector:(row)=>row.address,
        //     sortable:true
        // },




        // {
        //     name:" Annual Review Date ",
        //     selector:(row)=>row.driver_annual_review_date,
        //     sortable:true
        // },
        // {
        //     name:" Annual Review Due Date ",
        //     selector:(row)=>row.driver_annual_review_due_date,
        //     sortable:true
        // },
        // {
        //     name:" Safety Meeting Date ",
        //     selector:(row)=>row.driver_safety_meeting_date,
        //     sortable:true
        // },
        // {
        //     name:" Safety Meeting Due Date ",
        //     selector:(row)=>row.driver_meeting_due_date,
        //     sortable:true
        // },



    ]
    const [safetydate, setSafetyDate] = useState('')
    const [medicalDate, setMedicalDate] = useState('')
    const [licenceDate, setLicenceDate] = useState('')
    const [annualDate, setAnnualDate] = useState('')
    const[status,setStatus]=useState([]);
    
    const [reportname,setReportName] = useState('All Driver')
    const DriverStatus = (e) => {
        var value = e.target.value
        setListType(e.target.value)
        if (value == "all") {
            setReportName("All Drivers")
            allDriver();
        }
        else if (value == "active") {
            setReportName("Active Drivers")
            activeDriver()
        }
        else {
            setReportName("Inactive Drivers")
            inActiveDriver()
        }
        setMedicalDate("default")
        setLicenceDate("default")
        setSafetyDate("default")
        setAnnualDate('default')
    }
    const safetyDueDate = (e) => {
        var value = e.target.value
        setSafetyDate(value)
        if (value == "expire") {
            setReportName("Driver Safety Expiry")
            safetyExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            setReportName("Driver Safety About to Expiry")
            safetyAboutExpiry()
        }
        setMedicalDate("default")
        setLicenceDate("default")
        setAnnualDate('default')
    }
    const MedicalDate = (e) => {
        var value = e.target.value
        setMedicalDate(value)
        if (value == "expire") {
            setReportName("Driver Medical Expiry")
            mdicalExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            setReportName("Driver Medical About to Expiry")
            medicalAboutExpiry()
        }
        setLicenceDate("default")
        setSafetyDate("default")
        setAnnualDate('default')
    }
    const LicenceDate = (e) => {
        var value = e.target.value
        setLicenceDate(value)
        if (value == "expire") {
            setReportName("Driver License Expiry")
            licenceExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            setReportName("Driver License Expiry")
            licenceAboutExpiry()
        }
        setMedicalDate("default")
        setSafetyDate("default")
        setAnnualDate('default')
    }
    const AnnualDate = (e) => {
        var value = e.target.value
        setAnnualDate(value)
        if (value == "expire") {
            setReportName("Driver Annual Review Expiry")
            annualReviewExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            setReportName("Driver Annual Review About to Expiry")
            annualReviewAboutExpiry()
        }
        setMedicalDate("default")
        setLicenceDate("default")
        setSafetyDate("default")
    }
    const safetyExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.annual_safety_expiry_date));
        setFilter(filteredArray);

    }
    const safetyAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0);
        setFilter(filteredArray);
    }
    const mdicalExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.driver_medical_expiry_date));
        setFilter(filteredArray);

    }
    const medicalAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.driver_medical_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_medical_expiry_date)) > 0);
        setFilter(filteredArray);
    }
    const licenceExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.driver_licence_expiry_date));
        setFilter(filteredArray);

    }
    const licenceAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.driver_licence_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_licence_expiry_date)) > 0);
        setFilter(filteredArray);
    }
    const annualReviewExpiry = () => {

        const filteredArray = status.filter(row => comparisionOfDates(row.driver_annual_review_due_date));
        setFilter(filteredArray);

    }
    const annualReviewAboutExpiry = () => {
        const filteredArray = status.filter(row => differenceInDays(new Date(), new Date(row.driver_annual_review_due_date)) <= 30 && differenceInDays(new Date(), new Date(row.driver_annual_review_due_date)) > 0);
        setFilter(filteredArray);
    }
    const differenceInDays = (date1, date2) => {
        let one_day = 1000 * 60 * 60 * 24;
        let Result = Math.round((date2.getTime() - date1.getTime()) / one_day);
        let Final_Result = Result.toFixed(0);

        return (Final_Result);
    };
    const comparisionOfDates = (date1) => {
        const currentDate = new Date();
        const dueDate = new Date(date1);
        // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
        currentDate.setHours(0, 0, 0, 0);
        dueDate.setHours(0, 0, 0, 0);
        return currentDate >= dueDate;
    }
    const tableHeader = {
        rows: {
            style: {

            },
        },
        headCells: {
            style: {

            },
        },
        cells: {
            style: {
                maxWidth: '180px'
            },
        },

    }
    // for Sorting 
    useEffect(() => {
        const result = data.filter((item) => {
            return item.driver_id.toLowerCase().match(searchdriverid.toLowerCase())
        })
        setFilter(result)
    }, [searchdriverid])

    useEffect(() => {
        const result = data.filter((item) => {
            return item.province.toLowerCase().match(searchprovince.toLowerCase())
        })
        setFilter(result)
    }, [searchprovince])

    useEffect(() => {
        const result = data.filter((item) => {
            return item.driver_licence_no.toLowerCase().match(searchlicence.toLowerCase())
        })
        setFilter(result)
    }, [searchlicence])

    useEffect(() => {
        const result = data.filter((item) => {
            return item.city.toLowerCase().match(searchCity.toLowerCase())
        })
        setFilter(result)
    }, [searchCity])


    useEffect(() => {
        const result = data.filter((item) => {
            return item.first_name.toLowerCase().match(searchdname.toLowerCase())
        })
        setFilter(result)
    }, [searchdname])

    useEffect(() => {
        const result = data.filter((item) => {
            return item.cell.toLowerCase().match(searchcell.toLowerCase())
        })
        setFilter(result)
    }, [searchcell])


    useEffect(() => {
        const result = data.filter((item) => {
            return item.company_name.toLowerCase().match(searchcompanyname.toLowerCase())
        })
        setFilter(result)
    }, [searchcompanyname])


    useEffect(() => {
        const result = data.filter((item) => {
            return item.driver_safety_meeting_date.toLowerCase().match(safetymeeting.toLowerCase())
        })
        setFilter(result)
    }, [safetymeeting])

    const [listType, setListType] = useState(null);


    return (
        <div>
            <MyNavbar />
            <div className="container-fluid">

                {/********************Filter div ************************ */}
                <Form >
                    <Card className="shadow mb-3 filter-card">
                        <Card.Header className="py-2 d-flex align-items-center" style={{ height: '50px' }}>
                            <h6
                                className="h6 mb-0 text-gray1-800 font-weight-bold text-primary mr-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setIsShowing(!isShowing)}
                            >{isShowing ? 'Hide Filters' : 'Show Filters'}</h6>
                        </Card.Header>
                        {isShowing ? (
                            <Card.Body className="card-body pb-2">
                                <div className="form-row">

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Driver ID</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchdriverid}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setSearchDriverid(e.target.value)}

                                        />


                                    </div>

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Driver Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchdname}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setDname(e.target.value)}

                                        />


                                    </div>

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Licence #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchlicence}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setLicence(e.target.value)}
                                        />
                                    </div>
{/* 
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchCity}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setCity(e.target.value)}
                                        />
                                    </div> */}
 
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Cell #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchcell}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setCell(e.target.value)}
                                        />
                                    </div>


                                    {/* <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Company Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchcompanyname}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setCompany(e.target.value)}
                                        />
                                    </div> */}

                                    {/* <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Province</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchprovince}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setProvence(e.target.value)}
                                        />
                                    </div> */}

{/* 
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Safety Due Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={safetymeeting}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setSafetyMeeting(e.target.value)}
                                        />
                                    </div> */}
                                     
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">DL Expiry Date </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={licenceDate}
                                            onChange={LicenceDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>
                                   


                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Medical Expiry Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={medicalDate}
                                            onChange={MedicalDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>


                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Annual Review Due Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={annualDate}
                                            defaultValue={annualDate}
                                            onChange={AnnualDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Safety Meeting Due Date</Form.Label>
                                        <Form.Select
                                            value={safetydate}
                                            onChange={safetyDueDate}
                                            className="form-control"
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>
                                </div>
                            </Card.Body>
                        ) : null}
                    </Card>
                </Form>

                {/****************************End of the Filter div ********************************8 */}

                {/********************** Header  *****************************************/}
                <Card className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Driver Report</h5>
                        <div className='d-flex'>
                   <Button onClick={()=>window.location.reload()} variant="primary" className="btn-sm mr-2">Refresh</Button>
                            <Button onClick={() => sendPDFMail(filter)} variant="primary" className="btn-sm mr-2">{btnmailtext}</Button>
                            <Button onClick={generatePdf} variant="primary" className="btn-sm mr-2">Print</Button>
                            <Button onClick={handleClick} variant="primary" className="btn-sm mr-2">Download</Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleClosePDF}>PDF</MenuItem>
                                <MenuItem onClick={handleCloseCSV}>CSV</MenuItem>
                            </Menu>
                            <Form.Select
                                className="form-control"
                                value={listType ?? 'active'}
                                onChange={DriverStatus}
                                style={{ width: 'auto', fontSize: '14px', height: '30px', padding: '0px 8px' }}
                            >
                                <option  value='all'>All Drivers</option>
                                <option  value='active'>Active Drivers</option>
                                <option  value='archive'>Archived Drivers</option>
                            </Form.Select>
                        </div>
                    </Card.Header>

                    {/**************End of Header************************ */}

                    {/** *************************** My DATA TABLE  ********************************* */}

                    <div ref={contetPdf} >
                        <DataTable
                            customStyles={tableHeader}
                            columns={columns}
                            data={filter}
                            pagination
                            highlightOnHover
                        />
                    </div>

                    {/** ***************************END OF My DATA TABLE  ********************************* */}

                </Card>
            </div>
            <Footer/>
        </div>
    );
}
