import React from 'react'
import { useParams,useNavigate, json } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import MyNavbar from '../Mynavbar';
import Apiaddres from '../Apiaddres';
import Footer from './Footer';
export default function AddDriver() {
    document.title = "Add Driver | Smart Trucking Services";
    const cmpname = sessionStorage.getItem("legalName")
    let navigate = useNavigate();
    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
          navigate("/login");
        }
        
      }, [navigate]);
      
    const cardRef = useRef();
const [driverId,setDriverId] = useState('')
  const [firstName,setfirstName] = useState('')
  const [lastName,setlastName] = useState('')
  const [dob,setDOb] = useState('')
  const [city,setCity] =useState('')
  const [address,setAddress] = useState('')
  const [province,setProvince] = useState('')
  const [postalCode,setPostalCode] = useState('')
  const [cellNo,setCellNo] = useState('')
  const [licenceno,setLicenceno] = useState('')
  const [company,setCompany] = useState(cmpname)
  const [licenceExpiry,setLicenceExpiry] = useState('')
  const [medicalExpiry,setMedicalExpiry] = useState('')
  const [annualReiew,setAnnualReciew] =useState('')
  const [annualExpiry,setAnnualExpiry] = useState('')
  const [safetyMeeting,setSafetyMeeting] = useState('')
  const [meetingExpiry,setMeetingExpiry] = useState('')
  const [savedialogalter,setsavedialogalert] = useState('none')
  
  const [message, setMessage] = useState('')
  const handleSubmit=(e)=>{
    e.preventDefault();
    const userId = sessionStorage.getItem('clientid')
    fetch(Apiaddres.address+"/adddriver.php",{
        method:'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            firstName:firstName,
            lastName:lastName,
            dob:dob,
            city:city,
            address:address,
            province:province,
            postalCode:postalCode,
            cellNo:cellNo,
            licenceno:licenceno,
            company:company,
            licenceExpiry:licenceExpiry,
            medicalExpiry:medicalExpiry,
            annualReiew:annualReiew,
            annualExpiry:'',
            safetyMeeting:safetyMeeting,
            meetingExpiry:'',
            driver_id:driverId,
            userId:userId
        })
    }).then((res)=>res.json())
    .then((jsres)=>{
        console.log(jsres)
        if(jsres=="success"){
            setsavedialogalert('inline-block')
            setMessage('Driver Added Successfully')
        }
        else{
           
            setsavedialogalert('inline-block')
            setMessage('Driver id already exist.')
        }
    })
  }
  return (
    <div>
    <MyNavbar/>
     <div className="container-fluid">
             
             <form onSubmit={handleSubmit} >
                 <Card ref={cardRef} className="shadow mb-4">
                     <Card.Header className="d-flex align-items-center justify-content-between">
                         <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Add Driver</h5>
                         <div>  
                         <Button onClick={()=>window.location.reload()} variant="primary" className="btn-sm " style={{marginRight:'10px'}} >ADD</Button>
                         <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
                         </div>
                     </Card.Header>
                     <Card.Body>
                         
         
                     <fieldset className='mb-3'>
                             <legend>Driver info</legend>
                             <div className="form-row">
                                 
                             <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Driver Id</label>
                                     <input
                                     type='text'
                                     value={driverId}
                                     onChange={(e)=>setDriverId(e.target.value)}
                                     className='form-control'  
                                     
                                     />
                                 </div>

                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>First Name *</label>
                                     <input
                                     type='text'
                                     value={firstName}
                                     onChange={(e)=>setfirstName(e.target.value)}
                                     className='form-control'  
                                     required
                                     />
                                 </div>
         
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Last Name *</label>
                                     <input
                                     value={lastName}
                                     onChange={(e)=>setlastName((e.target.value))}
                                     className='form-control'
                                     type='text'
                                     required
                                     />
                                 </div>
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>DOB</label>
                                     <input
                                     value={dob}
                                     onChange={(e)=>setDOb((e.target.value))}
                                     type='date'
                                     className='form-control'  
                                     />
                                 </div>
         
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>City</label>
                                     <input
                                     value={city}
                                     onChange={(e)=>setCity(e.target.value)}
                                     className='form-control'
                                     type='text'
                                     />
                                 </div>
         
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Address</label>
                                     <input
                                     value={address}
                                     onChange={(e)=>setAddress(e.target.value)}
                                     type='text'
                                     className='form-control'  
                                     />
                                 </div>
         
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Province</label>
                                     <input
                                     value={province}
                                     onChange={(e)=>setProvince(e.target.value)}
                                     className='form-control'
                                     type='text'
                                     />
                                 </div>
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Postal Code</label>
                                     <input
                                     value={postalCode}
                                     onChange={(e)=>setPostalCode(e.target.value)}
                                     type='text'
                                     className='form-control'  
                                     />
                                 </div>
         
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Cell</label>
                                     <input
                                     value={cellNo}
                                     onChange={(e)=>setCellNo(e.target.value)}
                                     className='form-control'
                                     type='text'
                                     />
                                 </div>
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>License #*</label>
                                     <input
                                     value={licenceno}
                                     onChange={(e)=>setLicenceno(e.target.value)}
                                     type='text'
                                     required
                                     className='form-control'  
                                     />
                                 </div>
         
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>License Expiry Date *</label>
                                     <input
                                     value={licenceExpiry}
                                     required
                                     onChange={(e)=>setLicenceExpiry(e.target.value)}
                                     className='form-control'
                                     type='date'
                                     />
                                 </div>
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Medical Expiry Date *</label>
                                     <input
                                     required
                                     value={medicalExpiry}
                                     onChange={(e)=>setMedicalExpiry(e.target.value)}
                                     type='date'
                                     className='form-control'  
                                     />
                                 </div>
         
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Annual Review Date *</label>
                                     <input
                                     value={annualReiew}
                                     required
                                     onChange={(e)=>setAnnualReciew(e.target.value)}
                                     className='form-control'
                                     type='date'
                                     />
                                 </div>                             
         
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Safety Meeting Date*</label>
                                     <input
                                     value={safetyMeeting}
                                     required
                                     onChange={(e)=>setSafetyMeeting(e.target.value)}
                                     className='form-control'
                                     type='date'
                                     />
                                 </div>
                                       
                                 <div className="col-xl-4 col-md-6 mb-3">
                                     <label>Company Name</label>
                                     <input
                                     disabled
                                     value={company}
                                     onChange={(e)=>setCompany}
                                     className='form-control'
                                     type='text'
                                     />
                                 </div>
                                 
                             </div>
         
                         </fieldset>
         
         
                     </Card.Body>
                     <Card.Footer className='text-right'>
                         <button 
                             type="submit" 
                            
                             className="btn btn-primary btn-submit w-100px"
                             > Submit </button>
                     </Card.Footer>
                 </Card>
             </form>
         
         {/************************* Message Box Save Check *****************************/}
         
             <div style={{display:savedialogalter}} >
             <div>
         <div className="card shadow mb-4" style={{
             position: 'fixed',
             height: 'calc(100vh + 10px)',
             width: 'calc(100vw + 10px)',
             top: '-10px',
             left: '-10px',
             background: '#000000dd',
             display: 'flex',
             zIndex: 100,
             justifyContent: 'center',
             alignItems: 'center',
         }}>
         
             <h4 className="h4 mb-1 mt-2 text-white">
                 Note
             </h4>
             <div > <p className='text-white' > {message}  </p> </div>
                 
                 <div>
                     <button onClick={()=>setsavedialogalert('none')}  className="btn btn-success btn-submit w-100px mx-2">Okay</button>
                 </div>
             
         </div>
         </div>
             </div>
         
         
         </div>
         <Footer/>
    </div>
  )
}
