import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Navbar from "../Mynavbar";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component'
import Apiaddres from '../Apiaddres'
import { useReactToPrint } from 'react-to-print'
import Filter from '../Filters/Filter';
import useFetch from '../CustomComponents/useFetch';
import Paginate from '../CustomComponents/Paginate';
import DeletePopup from '../CustomComponents/DeletePopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Dropdown, Form, FormCheck, Image, Table } from 'react-bootstrap';
import ActionPopup from '../CustomComponents/ActionPopup';
import MyNavbar from "../Mynavbar";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Footer from "./Footer";
export default function TruckDetails() {
    document.title = "Truck Detail | Smart Trucking Services";

    const navigate = useNavigate();
    const lname = sessionStorage.getItem("legalName")
    const [data, setData] = useState([])
    const [searchvin, setSearchVin] = useState('')
    const [searchlname, setLname] = useState('')
    const [searchplateno, setPlatno] = useState('')
    const [searchunit, setUnit] = useState('')
    const [filter, setFilter] = useState([])
    const [isShowing, setIsShowing] = useState(true);


    const columns = [
        {
            name: " Unit # ",
            selector: (row) => row.unit_no,
            sortable: true,
            cell: (row) => {
                return (
                    <Link to={`/edit-truck/${row.unit_no}`} > {row.unit_no} </Link>
                )
            }

        },
        {
            name: " Legal Name ",
            selector: (row) => row.legal_name,
            sortable: true
        },
        {
            name: " VIN # ",
            selector: (row) => row.vin_no,
            sortable: true
        },
        {
            name: " Plate # ",
            selector: (row) => row.plat_no,
            sortable: true
        },
        {
            name: " Last Anual Safety Date ",
            selector: (row) => row.last_annual_safety_date,
            sortable: true
        },
        {
            name: " Anual Safety Expiry Date ",
            selector: (row) => row.annual_safety_expiry_date,
            sortable: true,
            cell:(row)=>{
                return row.last_annual_safety_date==null? (
                    <div>  
                      
                    </div>
                  ) : (
                      <div> {row.annual_safety_expiry_date} </div>
                  );
            }
        },
        {
            name: " Last PM Date ",
            selector: (row) => row.last_pm_date,
            sortable: true
        },
        {
            name: " Next PM Expiry Date ",
            selector: (row) => row.next_pm_due_date,
            sortable: true
        },

    ]
    const tableHeader = {
        rows: {
            style: {

            },
        },
        headCells: {
            style: {

            },
        },
        cells: {
            style: {
                maxWidth: '180px'
            },
        },
    }
    const pmExpiry = () => {

        const filteredArray = data.filter(row => comparisionOfDates(row.next_pm_due_date));
        const finalfilteredArray = filteredArray.filter(row => row.next_pm_due_date !== null);
        setFilter(finalfilteredArray);
    }
    const pmAboutExpiry = () => {

        const filteredArray = data.filter(row => differenceInDays(new Date(), new Date(row.next_pm_due_date)) <= 30 && differenceInDays(new Date(), new Date(row.next_pm_due_date)) > 0);
        setFilter(filteredArray);
    }
    const [safetyDAte, setSafetyDate] = useState(' ')
    const [pmdate, setPmDate] = useState(' ')

    const SafetyDueDate = (e) => {
        var value = e.target.value
        setSafetyDate(value)
        if (value == "expire") {
            safetyExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            safetyAboutExpiry()
        }
        setPmDate('default')
    }
    const PMDueDate = (e) => {
        var value = e.target.value
        setPmDate(value)
        if (value == "expire") {
            pmExpiry()
        }
        else if(value=="default"){
            window.location.reload()
        }
        else {
            pmAboutExpiry()
        }
  
        setSafetyDate('default')
    }
    const safetyExpiry = () => {
        const filteredArray = data.filter(row => comparisionOfDates(row.annual_safety_expiry_date));
        const finalfilteredArray = filteredArray.filter(row=> row.annual_safety_expiry_date != null)
        setFilter(finalfilteredArray);
    }
    const safetyAboutExpiry = () => {
        const filteredArray = data.filter(row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30 && differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) > 0);
        setFilter(filteredArray);
        //console.log("About Expire" + filteredArray)
    }
    const differenceInDays = (date1, date2) => {
        let one_day = 1000 * 60 * 60 * 24;
        let Result = Math.round((date2.getTime() - date1.getTime()) / one_day);
        let Final_Result = Result.toFixed(0);

        return (Final_Result);
    };
    const comparisionOfDates = (date1) => {
        const currentDate = new Date();
        const dueDate = new Date(date1);
        // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
        currentDate.setHours(0, 0, 0, 0);
        dueDate.setHours(0, 0, 0, 0);
        return currentDate >= dueDate;
    }

    /** Conditional Row Style************************  */
    const conditionalRowStyles = [

        {
            // those who are goining to be expired 
            when: row => differenceInDays(new Date(), new Date(row.annual_safety_expiry_date)) <= 30,
            style: {
                backgroundColor: '#ff7bb9',
                color: 'black',
                '&:hover': {
                    backgroundColor: '#ff7bb9',
                    color: 'black',
                },
            },
        },
        { //Those who are expired annual_safety_expiry_date
            when: row => comparisionOfDates(row.annual_safety_expiry_date),
            style: {
                backgroundColor: '#8a5082',
                color: 'black',
                '&:hover': {
                    backgroundColor: '#8a5082',
                    color: 'black',
                },
            },
        },
        { //Those who are expired next_pm_date
            when: row => comparisionOfDates(row.next_pm_due_date),
            style: {
                backgroundColor: '#6f5f90',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#6f5f90',
                    color: 'white',
                },
            },
        },
    ];


    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
            navigate("/login");
        }

    }, [navigate]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(Apiaddres.address + '/truckdetails.php?lname=' + lname);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                if (jsonData != "failed") {
                    setData(jsonData);
                    setFilter(jsonData);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [])

    // for Sorting 
    useEffect(() => {
        const result = data.filter((item) => {
            return item.vin_no.toLowerCase().match(searchvin.toLowerCase())
        })
        setFilter(result)
    }, [searchvin])

    useEffect(() => {
        const result = data.filter((item) => {
            return item.legal_name.toLowerCase().match(searchlname.toLowerCase())
        })
        setFilter(result)
    }, [searchlname])

    useEffect(() => {
        const result = data.filter((item) => {
            return item.unit_no.toLowerCase().match(searchunit.toLowerCase())
        })
        setFilter(result)
    }, [searchunit])

    useEffect(() => {
        const result = data.filter((item) => {
            return item.plat_no.toLowerCase().match(searchplateno.toLowerCase())
        })
        setFilter(result)
    }, [searchplateno])


    /** ********************************  To be  delete  ************************************************************* */

    const [listType, setListType] = useState(null);






    /*************************End of to be delete*************************************88 */


    return (
        <div>
            <MyNavbar />
            <div className="container-fluid">

                {/********************Filter div ************************ */}
                <Form >
                    <Card className="shadow mb-3 filter-card">
                        <Card.Header className="py-2 d-flex align-items-center" style={{ height: '50px' }}>
                            <h6
                                className="h6 mb-0 text-gray1-800 font-weight-bold text-primary mr-3"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setIsShowing(!isShowing)}
                            >{isShowing ? 'Hide Filters' : 'Show Filters'}</h6>
                        </Card.Header>
                        {isShowing ? (
                            <Card.Body className="card-body pb-2">
                                <div className="form-row">

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Unit #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchunit}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setUnit(e.target.value)}

                                        />


                                    </div>

                                    {/* <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Legal Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchlname}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setLname(e.target.value)}

                                        />


                                    </div> */}

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Plate #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchplateno}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setPlatno(e.target.value)}

                                        />


                                    </div>

                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">VIN #</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={searchvin}
                                            className='w-25form-control'
                                            placeholder='search'
                                            onChange={(e) => setSearchVin(e.target.value)}

                                        />


                                    </div>

                                    <div className="col-xl-3 col-md-6 mb-3"></div>
                                    
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">Annual Safety Expiry Date</Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={safetyDAte}
                                            onChange={SafetyDueDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>
                                    <div key={filter.name} className="col-xl-3 col-md-6 mb-3">
                                        <Form.Label className="mb-1">PM Expiry Date </Form.Label>
                                        <Form.Select
                                            className="form-control"
                                            value={pmdate}
                                            onChange={PMDueDate}
                                        >
                                            <option value='default'> </option>
                                            <option value='expire'>Expired</option>
                                            <option value='aboutto'>About To Expire</option>

                                        </Form.Select>
                                    </div>

                                </div>
                                {/* <div style={{display:'flex',flexDirection:'row',marginLeft:'10px'}} >
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#8a5082',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >Annual Safety expired </label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#ff7bb9',height:'10px',width:'10px'}} ></div>
                           <label style={{marginLeft: '5px',paddingTop:'5px'}} >Annual Safety about to expire </label>
                           </div>
                           <div style={{display:'flex',flexDirection:'row',marginLeft:'10px', alignItems: 'center'}} >
                           <div style={{backgroundColor:'#6f5f90',height:'10px',width:'10px'}} ></div><label style={{marginLeft: '5px',paddingTop:'5px'}} >PM Expired</label>
                           </div>
                           
                           
                           </div> */}
                            </Card.Body>
                        ) : null}
                    </Card>
                </Form>

                {/****************************End of the Filter div ********************************8 */}

                {/********************** Header  *****************************************/}
                <Card className="shadow mb-4">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Truck List</h5>
                           <div className='d-flex'>
                            
                   <Button onClick={()=>window.location.reload()} variant="primary" className="btn-sm mr-2">Refresh</Button>
                           </div>
                    </Card.Header>

                    {/**************End of Header************************ */}

                    {/** *************************** My DATA TABLE  ********************************* */}

                    <div >
                        <DataTable
                            customStyles={tableHeader}
                            columns={columns}
                            data={filter}
                            pagination
                            highlightOnHover
                        />
                    </div>

                    {/** ***************************END OF My DATA TABLE  ********************************* */}

                </Card>
            </div>
            <Footer/>
        </div>
    )
}
