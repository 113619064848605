import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useEffect,useState } from "react";

const MyNavbar = () => {
    const currentPath = useLocation().pathname.split('/')[1];
    
  const userData = sessionStorage.getItem("legalName")
//   console.log(userData?.username)
  const [username,setusername] = useState('');
    let navigate = useNavigate('nonmae');
    useEffect(() => {
        setusername(userData);
        
      }, [username]);
    const handleLogout = () => {
        sessionStorage.setItem("loggedIn","");
        sessionStorage.setItem("userdata","");
        sessionStorage.setItem("legalName","");
        sessionStorage.clear();
        navigate('/login')
    }
    const location = useLocation();
    const handleSubmit=()=>{
        //setPasswordPage('none')
    }
    const handleClick = (path) => {
        if (path === location.pathname) {
            window.location.reload(); // Reload the page if already on the same path
        } else {
            navigate(path);
        }
    };
const [passwordPage,setPasswordPage] = useState('none')
    return ( 
       < div>
        <nav className="navbar navbar-expand navbar-light topbar mb-3 static-top shadow" style={{ backgroundColor: '#01315A' }}>
            <Navbar.Brand>
                <img  onClick={()=>navigate('/Dashboard')} src="https://www.smartrucking.ca/images/logo.png"/>
            </Navbar.Brand>
            <ul className="navbar-nav">
            <Nav.Link as={Link} to="/Dashboard" onClick={() => handleClick('/Dashboard')}> Dashboard</Nav.Link>
                <NavDropdown title="Trucks">
                    <NavDropdown.Item as={Link} to="/truck-details" onClick={() => handleClick('/truck-details')}>Truck List</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Drivers">
                    <NavDropdown.Item as={Link} to="/DriverList" onClick={() => handleClick('/DriverList')}>Drivers List</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/add-driver" onClick={() => handleClick('/add-driver')}>Add Driver</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Trailers">
                    <NavDropdown.Item as={Link} to="/trailerlist" onClick={() => handleClick('/trailerlist')}>Trailer List</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/add-trailer" onClick={() => handleClick('/add-trailer')}>Add Trailer</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Reports">
                    <NavDropdown.Item as={Link} to="/truck-report" onClick={() => handleClick('/truck-report')}>Truck Report</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/driver-report" onClick={() => handleClick('/driver-report')}>Driver Report</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/trailer-report" onClick={() => handleClick('/trailer-report')}>Trailer Report</NavDropdown.Item>
                </NavDropdown>
                
                
            </ul> 
            <ul className="navbar-nav ml-auto">
            <NavDropdown title={username}>
                    <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
            </ul>
        </nav>
           </div>
           
     );
}
 
export default MyNavbar;