import React from 'react'
const footerStyle = {
  backgroundColor: "rgb(1, 49, 90)",
  color: "white",
  textAlign: "left",
  position: "fixed", // Stick to the bottom
  left: 0,
  bottom: 0,
  width: "100%",
};
const contentStyle = {
  marginBottom: "100px", // Adjust this value to match your footer's height
};
export default function Footer() {
  return (
    <div>
       <div style={contentStyle}>
        {/* Your main content goes here */}
      </div>
      <footer  style={footerStyle}>
    <div className="text-left p-3">
    Copyright © <a style={{color: "#ff8442"}} target='_blanck' href="https://smartrucking.ca/">Smart Trucking Services</a> 2014. All Rights Reserved. Smart Trucking Services one stop shop for all trucking services <br/>
Design by <a style={{color: "#ff8442"}} target='_blanck' href="https://www.sheikhskilledsolutions.com/">Sheikh Skilled Solutions Inc</a>.
    </div>

  </footer>
    </div>
    
  )
}
