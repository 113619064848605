import React from 'react'
import { useEffect, useState, useRef } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { Button, Card, Form, Modal } from 'react-bootstrap';
import Apiaddres from '../Apiaddres';
import NavbarAdmin from '../Mynavbar';
import Footer from './Footer';

export default function EditTruck() {
    document.title = "Edit Truck | Smart Trucking Services";
    const { id } = useParams();
    const cardRef = useRef();
    const [last_annual_date,setlastannouldate] = useState('')
    const [pmdate,setpmdate] = useState('')
    const [nextpmdate,setnextpmdate] = useState(sessionStorage.getItem("pmdays"))
    let navigate = useNavigate();
    const [savedialogalter,setsavedialogalert] = useState('none')
    const [message,setmessage] = useState('')

    useEffect(() => {
        const loggedIn = sessionStorage.getItem("loggedIn");
        if (!loggedIn) {
          navigate("/login");
        }
        
      }, [navigate]);
      useEffect(()=>{
        const fetchData =  () => {

                fetch(Apiaddres.address+'/truckinfo.php?unit_no='+id).then((res)=>res.json())
                .then((jsres)=>{
                    jsres.forEach(data => {
                   
                    setlastannouldate(data.last_annual_safety_date)
                    setpmdate(data.last_pm_date)
                    // if(data.pm_due_days==null){
                    //     setnextpmdate(" ")
                    // } 
                    // else{
                    //     setnextpmdate(data.pm_due_days)
                    // }
                    if(data.last_annual_safety_date==null){
                        setlastannouldate(" ")
                    } 
                    else{
                        setlastannouldate(data.last_annual_safety_date)
                    }
                    
                    });
                })
                
        };
    
        fetchData();
    },[])
    const handleUpdate = ()=>{
        fetch(Apiaddres.address+'/updatetruck.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              annual_last_date: last_annual_date,
              pm_date: pmdate,
              next_pm_date: nextpmdate,
              unit_no: id
            })
          }).then((res)=>res.json())
          .then((jsres)=>{
            if(jsres=="success"){
                setmessage("Truck Updated Successfully")
            }
            else{
                setmessage(" Updated Successfully")
            }
            setsavedialogalert('inline-block')
          })
    }
  return (
    <div>
        <NavbarAdmin/>
    
    <div className="container-fluid">
        
    <form>
        <Card ref={cardRef} className="shadow mb-4">
            <Card.Header className="d-flex align-items-center justify-content-between">
                <h5 className="h5 mb-0 text-gray1-800 font-weight-bold text-primary mr-3">Edit Truck Unit # {id} </h5>
                <Button onClick={() => navigate(-1)} variant="primary" className="btn-sm">Back</Button>
            </Card.Header>
            <Card.Body> 

                <fieldset className='mb-3'>
                    <legend>Edit info</legend>
                    <div className="form-row">
                        <div className="col-xl-4 col-md-6 mb-3">
                            <label>Last Annual  Safety Date</label>
                            <input
                            type='date'
                            className='form-control'  
                            value={last_annual_date}
                            onChange={(e)=>setlastannouldate(e.target.value)}
                            />
                        </div>
                        <div className="col-xl-4 col-md-6 mb-3">
                            <label>PM Date</label>
                            <input
                            className='form-control'
                            type='date'
                            value={pmdate}
                            onChange={(e)=>setpmdate(e.target.value)}                            />
                        </div>
                        {/* <div className="col-xl-4 col-md-6 mb-3">
                            <label>Next Due PM Days</label>
                            <span  className='form-control'>
                            <input
                            type="radio"
                            value="90"
                            checked={nextpmdate === '90'}
                            onChange={(e)=>setnextpmdate('90')}
                            /> &nbsp;90 Days &nbsp; &nbsp;
                            <input
                            type="radio"
                            value="120"
                            checked={nextpmdate === '120'}
                            onChange={(e)=>setnextpmdate('120')}
                            /> &nbsp;120 Days
                            </span>
                        </div> */}
                        

                        
                        <label>
                            
                        </label>
                        
                    </div>

                </fieldset>

                            </Card.Body>
                <Card.Footer className='text-right'>
                <label>  </label>
                <button 
                    type="button" 
                    onClick={handleUpdate}
                    className="btn btn-primary btn-submit w-100px"
                    >UPDATE</button>
            </Card.Footer>
        </Card>
    </form>
    {/************************* Message Box Save Check *****************************/}

    <div style={{display:savedialogalter}} >
            <div>
      <div className="card shadow mb-4" style={{
            position: 'fixed',
            height: 'calc(100vh + 10px)',
            width: 'calc(100vw + 10px)',
            top: '-10px',
            left: '-10px',
            background: '#000000dd',
            display: 'flex',
            zIndex: 100,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <img src='images/confirm.svg' style={{width: '70px'}} />

            <h4 className="h4 mb-1 mt-2 text-white">
                Note
            </h4>
            <div > <p className='text-white' > {message}  </p> </div>
                
                <div>
                    <button onClick={()=>setsavedialogalert('none')}  className="btn btn-success btn-submit w-100px mx-2">Okay</button>
                </div>
            
        </div>
    </div>
            </div>

</div>
<Footer/>
</div>
  )
}
